import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../../Button';
import Avatar from '../../Avatar';

//#region
const UserCardStyle = createGlobalStyle`
  .ant-popover-title {
    border-bottom: none;
  }
`;
const UserInfoCardWrapper = styled.div`
  display: flex;
  width: 288px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 auto;
  .ant-btn {
    padding: 0 24px;
  }
`;

const NoImageAvatar = styled.div`
  width: 103px;
  height: 103px;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 32px;
  text-align: center;
  line-height: 103px;
  background-color: var(--color-fill-6-others-purple-500);
  color: var(--default-500-light);
`;
const InfoContentWrapper = styled.div`
  margin-left: 17px;
  height: 103px;
`;

const InfoContentTitle = styled.div`
  font-size: 15px;
  line-height: 1.6;
  font-weight: 600;
`;

const InfoContentDetail = styled.div`
  max-width: 150px;
  font-size: 13px;
  line-height: 1.38;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--default-500-dark);
`;

const InfoContentRole = styled(InfoContentDetail)`
  font-size: 12px;
  padding: 7px 0;
  color: var(--default-500-hint);
`;
//#endregion

const UserInfo = ({ memberName, role, avatar, textAvatar, accountHandler }) => {
  return (
    <UserInfoCardWrapper>
      <UserCardStyle />
      {avatar ? (
        <Avatar type='img' image={avatar} size={103} shape={'square'} />
      ) : (
        <NoImageAvatar>{textAvatar}</NoImageAvatar>
      )}
      <InfoContentWrapper>
        <InfoContentTitle>{memberName}</InfoContentTitle>
        <InfoContentRole>{role}</InfoContentRole>
        {accountHandler && (
          <Button onClick={accountHandler} buttonText='navbar.myAccount' type='black' width={'103px'} />
        )}
      </InfoContentWrapper>
    </UserInfoCardWrapper>
  );
};

export default UserInfo;
