import { VerticalInput } from '../../../component/Input';

const CaseCategoryForm = ({ control, error, alertMessage, changeName, validate }) => {
  return (
    <div>
      <VerticalInput
        label={'labSetting.form.name'}
        placeholder={'input.placeholder'}
        size={'large'}
        required={true}
        validationName={'name'}
        control={control}
        alertMessage={alertMessage}
        error={error}
        changeHandler={changeName}
        validateError={validate}
      />
    </div>
  );
};

export default CaseCategoryForm;
