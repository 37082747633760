import { Drawer } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../../images/icons-close.svg';
import { useDevice } from '../../../utils/hooks/useDevice';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: none;
    .ant-drawer-title {
      line-height: 32px;
      font-size: 22px;
    }
  }
  .ant-drawer-footer {
    border-top: none;
  }
`;

const DrawerComponent = ({ visible, handleClose, title, children, className, footer }) => {
  const { isTabletAndMobile } = useDevice();
  return (
    <StyledDrawer
      className={className}
      title={title}
      placement={'right'}
      onClose={handleClose}
      visible={visible}
      footer={footer}
      width={isTabletAndMobile ? '80%' : '34.28em'}
      closeIcon={<CloseIcon />}
      maskClosable={false}
    >
      {children}
    </StyledDrawer>
  );
};

export default DrawerComponent;

DrawerComponent.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.element,
  className: PropTypes.string,
  footer: PropTypes.element,
  width: PropTypes.string,
};

DrawerComponent.defaultProps = {
  handleClose: () => {},
  visible: false,
  title: '',
  className: '',
  width: '',
  footer: undefined,
  children: undefined,
};
