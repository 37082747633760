import { edit, get } from '../utils/api';

const requestUrl = `/laboratory`;

export default class LabSetting {
  static getLabSetting = async () => await get(requestUrl);

  static getLabSettingById = async params => await get(`${requestUrl}/${params}`);

  static createLab = async data => await edit({ url: requestUrl, method: 'post', data });

  static editLab = async data => await edit({ url: requestUrl, method: 'put', data });
}
