import { combineReducers } from '@reduxjs/toolkit';
import laboratoryReducer from './laboratorySlice';
import caseLiReducer from './caseLiSlice';
import casePayReducer from './casePaySlice';
import caseTypeReducer from './caseTypeSlice';

const settingPageReducer = combineReducers({
  laboratory: laboratoryReducer,
  caseLi: caseLiReducer,
  casePay: casePayReducer,
  caseType: caseTypeReducer,
});

export default settingPageReducer;
