import CasePay from '../../../models/casePay';
import { getCasePay, getCasePayStart, updateOriginalCasePay, filterDeleteData } from '../slice/casePaySlice';

export const getCasePayData = () => async dispatch => {
  dispatch(getCasePayStart());
  return await CasePay.getCasePay().then(res => dispatch(getCasePay(res)));
};

export const createCasePay = async (data, successCallback) => {
  return await CasePay.createCasePay(data).then(res => {
    if (!!res) successCallback();
  });
};

export const editCasePay = (data, successCallback) => async dispatch => {
  return await CasePay.editCasePay(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(updateOriginalCasePay(res));
    }
  });
};

export const deleteCasePay = (data, successCallback) => async dispatch => {
  return await CasePay.editCasePay(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(filterDeleteData(data));
    }
  });
};
