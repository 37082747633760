import _ from 'lodash';
import styled from 'styled-components';
import { Table } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import DrawerComponent from './Drawer';
import LabSettingForm from './LabSettingForm';
import { createLab, getLab, editLab, deleteLab as deleteLabData } from '../utils/handleLabData';
import { resetLabForm, selectLabItem, getDeleteTarget, resetDeleteForm } from '../slice/laboratorySlice';
import { labColumns } from '../utils/column';
import TableFilter from '../../../component/Button/TableFilterButton';
import { messageSuccess, messageError, messageWarning } from '@/component/Message';
import { Button } from '../../../component/Button';
import { scrollbar } from '@/constants/style';
import Empty from '../../../component/Empty';
import { sortBySelect } from '../utils/sortBySelect';
import DeleteModal from './DeleteModal';
import CustomizeModal from '../../../component/Modal';
import Spin from '../../../component/Spin';
import {
  resetUsedList,
  setClientError,
  setDuplicateName,
  setUsedCaseModalVisible,
} from '../../HomePage/slice/errorCenterSlice';
import { EllipsisText } from '../../../component/Display';
import Validate from '../../../models/validate';

//#region
const LabSettingContainer = styled.div`
  width: 100%;
  padding: 0 20px 31px;
  height: 100%;
  .labSetting__table-more {
    display: flex;
    width: 100%;
    justify-content: space-between;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s ease;
    &:focus-within {
      visibility: visible;
      opacity: 1;
    }
  }
  .labSetting__table {
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
    .ant-table .ant-table-tbody > tr {
      & > td:first-child {
        border-right: 2px solid var(--basic-fill-03-300);
      }
      &:hover .labSetting__table-more {
        visibility: visible;
        opacity: 1;
      }
      &:hover > td {
        background: var(--basic-fill-02-200);
      }
      &:focus-within {
        > td {
          background: var(--color-fill-1-primary-100);
        }
      }
    }
    .ant-table .ant-table-thead > tr {
      & > th:first-child {
        border-right: 2px solid var(--basic-fill-03-300);
      }
    }
    .ant-table-container table > thead > tr:first-child th:first-child,
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-radius: unset;
    }
    .ant-table.ant-table-middle .ant-table-tbody > tr > td {
      padding: 10px 24px;
    }
    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 24px;
    }
    .ant-table-body {
      ${scrollbar};
    }
  }
  .labSetting__table-isArchived {
    color: var(--default-500-hint);
    font-style: italic;
    line-height: 15px;
  }
  .labSetting__table-archivedAlert {
    font-size: 12px;
  }
`;
//#endregion

const labSelector = state => state.settingPageReducer.laboratory;
const errorSelector = state => state.homePageReducer.errorCenter;
const wsSelector = state => state.homePageReducer.ws;
const labSettingReselector = createSelector(labSelector, errorSelector, wsSelector, (laboratory, error, ws) => ({
  laboratory: laboratory.laboratory,
  lab: laboratory.lab,
  loading: laboratory.loading,
  editId: laboratory.editId,
  deleteName: laboratory.deleteTarget.name,
  deleteLab: laboratory.deleteTarget,
  errorMsgData: error.usedList,
  duplicateError: error.duplicateName,
  serverError: error.serverError,
  clientError: error.clientError,
  usedCaseModalVisible: error.usedCaseModalVisible,
  clinicId: ws?.ws?._id,
}));

const SettingContent = ({ visible, handleClose, handleShow, sort }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const {
    laboratory,
    lab,
    loading,
    editId,
    deleteName,
    deleteLab,
    errorMsgData,
    duplicateError,
    serverError,
    clientError,
    usedCaseModalVisible,
    clinicId,
  } = useSelector(labSettingReselector);
  const [filters, setFilters] = useState(['activate', 'isArchived']);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [archivedWarning, setArchivedWarning] = useState({
    visible: false,
    name: '',
    id: '',
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const btnText = !_.isEmpty(editId) ? 'button.save' : 'button.add';
  const title = !_.isEmpty(editId)
    ? f({ id: 'labSetting.editLabDrawerTitle' })
    : f({ id: 'labSetting.addLabDrawerTitle' });

  useEffect(() => {
    dispatch(getLab());
  }, [dispatch]);

  useEffect(() => {
    if (clientError) {
      messageError(f({ id: 'message.error' }));
      dispatch(setClientError(false));
    }
  }, [f, clientError, dispatch]);

  const submitHandler = async lab => {
    if (!_.isEmpty(editId)) {
      dispatch(
        editLab({ ...lab, name: lab.name.trim(), _id: editId }, () => {
          messageSuccess(f({ id: 'message.success' }));
          onCloseDrawer();
        }),
      );
    } else {
      await createLab({ ...lab, name: lab.name.trim() }, () => {
        messageSuccess(f({ id: 'message.add.success' }));
        dispatch(getLab());
        onCloseDrawer();
      });
    }
  };

  const onCloseDrawer = () => {
    handleClose();
    reset({ name: '' });
    dispatch(resetLabForm());
    dispatch(setDuplicateName(false));
  };

  const onEditLabItem = item => {
    dispatch(selectLabItem(item));
    setValue('name', item.name);
    handleShow();
  };

  const archiveLabItem = id => {
    dispatch(
      editLab({ _id: id, isArchived: true, isCommonly: false }, () =>
        messageSuccess(f({ id: 'message.labSetting.archivedLabSuccess' })),
      ),
    );
    setArchivedWarning({ id: '', name: '', visible: false });
  };

  const activatedLabItem = id => {
    dispatch(
      editLab({ _id: id, isArchived: false }, () => messageSuccess(f({ id: 'message.labSetting.activeLabSuccess' }))),
    );
  };

  const addCommonlyMark = (id, currentStatus) => {
    dispatch(editLab({ _id: id, isCommonly: !currentStatus }, () => messageSuccess(f({ id: 'message.success' }))));
  };

  const deleteLabItem = (data, lab) => {
    const successCallback = () => {
      dispatch(resetDeleteForm());
      messageWarning(f({ id: 'message.deleted' }));
    };
    dispatch(
      deleteLabData(
        { _id: lab._id, isDeleted: true, deletedReason: data.reason, deletedOperator: data.person },
        successCallback,
        () => setIsModalVisible(false),
      ),
    );
    setIsModalVisible(false);
  };

  const showModal = target => {
    dispatch(getDeleteTarget(target));
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    reset({ reason: '', person: '' });
    setIsModalVisible(false);
    dispatch(resetDeleteForm());
  };

  const filterHandler = value => setFilters([...value]);

  const showWarningModal = (id, name) =>
    setArchivedWarning({
      id,
      name,
      visible: true,
    });

  const deleteValidation = (id, name) => {
    Validate.deleteLabValidation(id).then(res => {
      if (!!res) {
        showModal({ id, name });
      }
    });
  };

  if (loading) {
    return <Spin />;
  }
  if (_.isEmpty(laboratory)) {
    return (
      <LabSettingContainer>
        <Empty description={'labSetting.lab.empty'} />
        <DrawerComponent
          handleClose={onCloseDrawer}
          visible={visible}
          title={title}
          footer={
            <Button
              type='primary'
              width={'150px'}
              size={'large'}
              onClick={handleSubmit(() => submitHandler(lab))}
              buttonText={btnText}
            />
          }
          children={<LabSettingForm control={control} error={errors} value={lab} validate={duplicateError} />}
        />
      </LabSettingContainer>
    );
  }
  return (
    <LabSettingContainer>
      <Table
        columns={labColumns({
          format: f,
          onEditLabItem,
          activatedLabItem,
          addCommonlyMark,
          TableFilter,
          filters,
          filterHandler,
          showModal,
          showWarningModal,
          deleteValidation,
        })}
        dataSource={sortBySelect({ source: laboratory, filters, sort })}
        loading={loading}
        pagination={false}
        className={'labSetting__table'}
        size='middle'
        scroll={{ x: 768 }}
        sticky={{ offsetHeader: 74, getContainer: () => document.querySelector('.setting__content') }}
      />
      <DrawerComponent
        handleClose={onCloseDrawer}
        visible={visible}
        title={title}
        footer={
          <Button
            type='primary'
            width={'150px'}
            size={'large'}
            onClick={handleSubmit(() => submitHandler(lab))}
            buttonText={btnText}
          />
        }
        children={<LabSettingForm control={control} error={errors} validateName={duplicateError} />}
      />
      <DeleteModal
        deleteTitle={'modal.delete.title.lab'}
        deleteName={deleteName}
        deleteModalVisible={isModalVisible}
        handleDelete={data => deleteLabItem(data, deleteLab)}
        handleCancel={handleCancel}
        failModalVisible={usedCaseModalVisible}
        handleModalFailVisible={() => {
          dispatch(setUsedCaseModalVisible(false));
          dispatch(resetUsedList());
        }}
        deleteFailedTitle={'modal.delete.fail.lab.title'}
        deleteFailedDescription={'modal.delete.fail.description'}
        usedList={errorMsgData}
        serverError={serverError}
        clinicId={clinicId}
      />
      <CustomizeModal
        title={
          <EllipsisText>
            <EllipsisText.Normal>{f({ id: 'labSetting.archived.lab' })}</EllipsisText.Normal>
            <EllipsisText.Ellipsis>{archivedWarning.name}</EllipsisText.Ellipsis>
            <EllipsisText.Normal>?</EllipsisText.Normal>
          </EllipsisText>
        }
        type={'warning'}
        visible={archivedWarning.visible}
        content={f({ id: 'lab.modal.warning.content' })}
        showCloseIcon={false}
        className={'wide'}
        handleCancel={() => setArchivedWarning({ id: '', name: '', visible: false })}
        handleOk={() => archiveLabItem(archivedWarning.id)}
        footer={
          <div>
            <Button
              buttonText='labSetting.table.filterByArchived'
              type={'warning'}
              width={'82px'}
              size={'large'}
              onClick={() => archiveLabItem(archivedWarning.id)}
            />
            <Button
              buttonText='button.cancel'
              type={'ghost-basic'}
              width={'82px'}
              size={'large'}
              onClick={() => setArchivedWarning({ id: '', name: '', visible: false })}
            />
          </div>
        }
      />
    </LabSettingContainer>
  );
};

export default SettingContent;
