import axios from 'axios';

import { getAccessToken, removeAuthTokens, saveAccessToken } from './token';
import { cas_api_url } from '@/config';

const instance = axios.create({
  baseURL: cas_api_url,
  withCredentials: true,
});

instance.interceptors.request.use(
  config => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    if (error.response) {
      const data = error.response.data.error;

      switch (data.code) {
        case 'ACCESS_TOKEN_IS_EXPIRED':
          await removeAuthTokens();
          try {
            return instance({
              url: `/auth/access-token:renew`,
              method: 'POST',
            }).then(response => {
              const newAccessToken = response.data;
              saveAccessToken(newAccessToken);
              error.config.headers.Authorization = `Bearer ${newAccessToken}`;
              return instance.request(error.config);
            });
          } catch (renewError) {
            return Promise.reject(renewError);
          }
        default:
          return Promise.reject(data);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export { instance };
