import algoliasearch from 'algoliasearch';
import { algolia, secret } from '../config';

export const autoComplete = (value = '', setLoading, page = 0, clinic_id, indexId, callback) => {
  const searchClient = algoliasearch(algolia, secret);
  const index = searchClient.initIndex(indexId);
  setLoading(true);
  return index
    .search(value, {
      filters: `clinic_id:${clinic_id}`,
      hitsPerPage: 10,
      page,
    })
    .then(({ hits, page }) => {
      return callback({ hits, page });
    })
    .catch(err => console.log('err', err.message));
};

export const autoCompleteInput = (value = '', clinic_id, indexId, callback) => {
  const searchClient = algoliasearch(algolia, secret);
  const index = searchClient.initIndex(indexId);

  return index
    .search(value, {
      filters: `clinic_id:${clinic_id}`,
    })
    .then(({ hits, page }) => {
      return callback({ hits, page });
    })
    .catch(err => console.log('err', err.message));
};
