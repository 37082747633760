import React, { useState, forwardRef, useEffect } from 'react';
import { Input } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import validateLimit from './validateLimit';

//#region
const CountingInputContainer = styled.div`
  position: relative;
  .text-count {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    right: 10px;
    font-size: 12px;
    color: var(--default-500-hint);
  }
`;
const StyledInput = styled(Input)`
  margin-top: 3px;
  border: ${({ alert }) => alert && '1px solid var(--default-500-danger)'};
  ::placeholder {
    ${props => (props.size !== 'large' ? `font-size: 13px;` : `font-size: 15px;`)}
  }
  &[disabled] {
    ::placeholder {
      color: var(--disabled-color);
    }
  }
`;
//#endregion

const CountingInput = forwardRef(
  ({ value, changeHandler, size, placeholder = 'input.placeholder', alert, disabled, limit }, ref) => {
    const { formatMessage: f } = useIntl();
    const [count, setCount] = useState(0);

    useEffect(() => {
      setCount(validateLimit(value, limit).count);
    }, [limit, value]);

    return (
      <CountingInputContainer>
        <StyledInput
          placeholder={f({ id: placeholder })}
          value={value}
          size={size}
          onChange={e => {
            const { value, count } = validateLimit(e.target.value, limit);
            setCount(count);
            changeHandler(value);
          }}
          alert={alert ? 'alert' : undefined}
          disabled={disabled}
          ref={ref}
        />
        <span className='text-count'>
          {count}/{limit}
        </span>
      </CountingInputContainer>
    );
  },
);

export default CountingInput;
