import { Button, Popover, Checkbox } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ReactComponent as DefaultSortIcon } from '../../images/icon-his-icons-funnel-fill-black.svg';
import { ReactComponent as SortIcon } from '../../images/icon-his-icons-funnel-fill.svg';

//#region
const TableFilterStyle = createGlobalStyle`
  .labSetting__table-filter {
    padding-top: 0;
    .ant-popover-content > .ant-popover-arrow {
      display: none;
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        padding: 0 23px 5px 0;
      }
      .ant-checkbox-group-item:first-child {
        padding: 5px 23px 22px 0;
      }
      .ant-checkbox-wrapper {
        font-size: 13px;
      }
      .ant-checkbox + span {
        padding-right: 20px;
        padding-left: 16px;
      }
    }
  }
  .labSetting__table-filterBtn.ant-btn-text:hover, .ant-btn-text:focus {
    background: transparent
  }
`;
//#endregion

const TableFilter = ({ options, value, onChange }) => {
  const content = <Checkbox.Group options={options} value={value} onChange={onChange} />;
  return (
    <React.Fragment>
      <TableFilterStyle />
      <Popover content={content} trigger='click' placement='bottom' overlayClassName={'labSetting__table-filter'}>
        <Button
          icon={_.isEmpty(value) ? <DefaultSortIcon /> : <SortIcon />}
          type='text'
          className='labSetting__table-filterBtn'
        />
      </Popover>
    </React.Fragment>
  );
};

export default TableFilter;

TableFilter.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

TableFilter.defaultProps = {
  options: [],
  value: [],
  onChange: () => {},
};
