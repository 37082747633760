import React, { useState } from 'react';
import { Button } from '../../component/Button';
import SettingContent from './component/LabSettingContent';
import SettingHeader from './component/LabSettingHeader';
import { ReactComponent as PlusIcon } from '../../images/icons-plus.svg';
import { CaseItemsContainer } from './CaseType';

const LabSetting = () => {
  const [visible, setVisible] = useState(false);
  const [sort, setSort] = useState('name');
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  return (
    <CaseItemsContainer className='setting__content'>
      <SettingHeader
        title='labSetting.title'
        sortHandler={setSort}
        sortValue={sort}
        content={
          <Button type='primary' width={'85px'} icon={<PlusIcon />} onClick={showDrawer} buttonText={'button.add'} />
        }
      />
      <SettingContent handleClose={onClose} visible={visible} handleShow={showDrawer} sort={sort} />
    </CaseItemsContainer>
  );
};

export default LabSetting;
