import { createSlice } from '@reduxjs/toolkit';

const lightBoxSlice = createSlice({
  name: 'sharedPage/lightBox',
  initialState: {
    images: [],
  },
  reducers: {
    setLightBoxImages: (state, action) => {
      state.images = action.payload;
    },
  },
});

export const { setLightBoxImages } = lightBoxSlice.actions;

export default lightBoxSlice.reducer;
