import { createSlice } from '@reduxjs/toolkit';

const shareSlice = createSlice({
  name: 'casePage/share',
  initialState: {
    _id: '',
    enable: false,
    loading: false,
    hasComment: false,
    password: '',
    sharedFiles: [],
    progress: {},
    caseLi: {},
    doctorName: false,
    labName: false,
    patientName: false,
    patientGender: false,
    patientBirth: false,
    teeth: false,
    project: false,
    estimatedReturnDate: false,
    patientReturnDate: false,
    treatmentCategory: false,
    remarks: false,
    otherDesc: false,
    shipmentNum: false,
    labNum: false,
    labPrice: false,
    paymentDate: false,
    receiptNo: false,
    paymentMethod: false,
    paymentDescription: false,
    bcc: [],
    targetKeys: [],
  },
  reducers: {
    getSharedDataStart: state => {
      state.loading = true;
    },
    getSharedDataSuccess: (state, action) => {
      state.loading = false;
      state._id = action.payload._id;
      state.enable = action.payload.enable;
      state.hasComment = action.payload.hasComment;
      state.progress = action.payload.progress;
      state.caseLi = action.payload.caseLi;
      state.sharedFiles = action.payload.sharedFiles;
      state.doctorName = action.payload.doctorName;
      state.labName = action.payload.labName;
      state.patientName = action.payload.patientName;
      state.patientGender = action.payload.patientGender;
      state.patientBirth = action.payload.patientBirth;
      state.teeth = action.payload.teeth;
      state.project = action.payload.project;
      state.estimatedReturnDate = action.payload.estimatedReturnDate;
      state.patientReturnDate = action.payload.patientReturnDate;
      state.treatmentCategory = action.payload.treatmentCategory;
      state.remarks = action.payload.remarks;
      state.labPrice = action.payload.labPrice;
      state.paymentDate = action.payload.paymentDate;
      state.receiptNo = action.payload.receiptNo;
      state.paymentMethod = action.payload.paymentMethod;
      state.paymentDescription = action.payload.paymentDescription;
      state.bcc = action.payload.bcc;
      state.password = action.payload.password;
      state.labNum = action.payload.labNum;
      state.shipmentNum = action.payload.shipmentNum;
      state.otherDesc = action.payload.otherDesc;
    },
    updateSharedCase: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { getSharedDataStart, getSharedDataSuccess, updateSharedCase } = shareSlice.actions;

export default shareSlice.reducer;
