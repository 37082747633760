import _ from 'lodash';

export const splitStr = (str, split) => {
  if (!str || !split || !_.isString(str) || str.length <= 1) return [str];
  return str.split(split);
};

export const parseAccountDetail = user => {
  if (!user) return {};

  let accountId, accountName, accountEmail, accountPhone;
  const { is_clinic_account = false, facebook, name, email, email_1, mobile, clinic = {} } = user;
  if (is_clinic_account) {
    accountName = clinic?.name;
    accountId = splitStr(email, '@')[0];
  } else {
    accountName = name;
    accountPhone = mobile;
    accountEmail =
      !_.isEmpty(facebook) && !_.isEmpty(facebook?.id) ? (!_.isEmpty(email_1) ? email_1 : undefined) : email;
  }

  return {
    accountId,
    accountName,
    accountEmail,
    accountPhone,
  };
};
