import moment from 'moment';
import { filterByDropDown } from './filterByDropDown';

export const sortBySelect = ({ source, filters, sort }) => {
  const defaultData = filterByDropDown({ source, filters });

  if (sort === 'name') {
    return defaultData.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  if (sort === 'latest') {
    return defaultData.sort((a, b) => {
      const unixA = a.lastUpdatedDate ? moment(a.lastUpdatedDate).unix() : moment(a.createdDate).unix();
      const unixB = b.lastUpdatedDate ? moment(b.lastUpdatedDate).unix() : moment(b.createdDate).unix();
      return unixB - unixA;
    });
  }
};

export const sortByASC = arr => {
  return arr.sort((a, b) => a.priority - b.priority);
};

export const sortByDESC = arr => {
  return arr.sort((a, b) => b.priority - a.priority);
};
