import { createSlice } from '@reduxjs/toolkit';

const commentSlice = createSlice({
  name: 'sharedPage/comments',
  initialState: {
    commentGroupLoading: false,
    commentLoading: false,
    commentGroupList: [],
    commentList: [],
    currentSelect: '',
    clickedMessageButton: true,
    uploadFiles: [],
  },
  reducers: {
    fetchCommentGroup: state => {
      state.commentGroupLoading = true;
    },
    getCommentGroupList: (state, action) => {
      state.commentGroupList = action.payload;
      state.commentGroupLoading = false;
    },
    fetchComment: state => {
      state.commentLoading = true;
    },
    getCommentList: (state, action) => {
      state.commentList = action.payload;
      state.commentLoading = false;
    },
    addToCommentGroupList: (state, action) => {
      const removeTemp = state.commentGroupList.filter(li => li._id !== 'temp').concat(action.payload);
      state.commentGroupList = removeTemp;
    },
    addTempCommentGroup: (state, action) => {
      state.commentGroupList.push(action.payload);
    },
    updateCommentGroupList: (state, action) => {
      state.commentGroupList = state.commentGroupList
        .filter(group => group._id !== action.payload._id)
        .concat(action.payload);
    },
    onDragEnd: (state, action) => {
      state.commentGroupList = state.commentGroupList.map(group => {
        if (group._id === action.payload._id) {
          return { ...group, x: action.payload.x, y: action.payload.y, updated_by: action.payload.updated_by };
        }
        return group;
      });
    },
    updateGroupData: (state, action) => {
      state.commentGroupList = state.commentGroupList.map(group => {
        if (group._id === action.payload._id) {
          return { ...group, ...action.payload };
        }
        return group;
      });
    },
    addToComment: (state, action) => {
      state.commentList.push(action.payload);
    },
    setCurrentSelect: (state, action) => {
      state.currentSelect = action.payload;
    },
    removeTempGroup: state => {
      state.commentGroupList = state.commentGroupList.filter(li => li._id !== 'temp');
    },
    preUploadFile: (state, action) => {
      state.uploadFiles = [...state.uploadFiles, action.payload];
    },
    updateUploadLoading: (state, action) => {
      state.uploadFiles = state.uploadFiles.map((f) => ({...f, loading: true}));
    },
    removeFile: (state, action) => {
      state.uploadFiles = state.uploadFiles.filter(file => file.uid !== action.payload);
    },
    resetFiles: state => {
      state.uploadFiles = [];
    },
    clickedMessageButton: (state, action) => {
      state.clickedMessageButton = action.payload;
    },
  },
});

export const {
  fetchCommentGroup,
  getCommentGroupList,
  addToCommentGroupList,
  addTempCommentGroup,
  updateCommentGroupList,
  onDragEnd,
  updateGroupData,
  addToComment,
  getCommentList,
  fetchComment,
  setCurrentSelect,
  removeTempGroup,
  preUploadFile,
  updateUploadLoading,
  removeFile,
  resetFiles,
  clickedMessageButton,
} = commentSlice.actions;

export default commentSlice.reducer;
