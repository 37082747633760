import { Tooltip, Button } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ReactComponent as StarIcon } from '../../images/icons-star.svg';
import { ReactComponent as StarIconFill } from '../../images/icons-star-fill.svg';
import { ReactComponent as StarIconFillDisable } from '../../images/icons-star-fill-disable.svg';

const StyledStar = styled(Button)`
  &.ant-btn-text:hover {
    background-color: var(--basic-fill-03-300);
  }
  &.ant-btn-text:focus,
  &.ant-btn-text:active {
    background-color: var(--color-fill-1-primary-100);
  }
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: var(--transparent-0);
  }
`;
const StarMark = ({ color, isMark, clickHandler, isLoading, isDisable }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Tooltip title={isMark ? f({ id: 'button.star.inactive' }) : f({ id: 'button.star.active' })} color={color}>
      <StyledStar
        shape='circle'
        type='text'
        icon={isDisable ? <StarIconFillDisable /> : isMark ? <StarIconFill /> : <StarIcon />}
        onClick={clickHandler}
        loading={isLoading}
        disabled={isDisable}
      />
    </Tooltip>
  );
};

export default StarMark;

StarMark.propTypes = {
  color: PropTypes.string,
  isMark: PropTypes.bool,
  clickHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisable: PropTypes.bool,
};

StarMark.defaultProps = {
  color: undefined,
  isMark: false,
  clickHandler: () => {},
  isLoading: false,
  isDisable: false,
};
