import { edit, get } from '../utils/api';

const requestUrl = `/paymentMethod`;

export default class CasePay {
  static getCasePay = async () => {
    const result = await get(requestUrl);
    return result;
  };

  static getCasePayById = async params => {
    const result = await get(`${requestUrl}/${params}`);
    return result;
  };

  static createCasePay = async data => {
    const result = await edit({ url: requestUrl, method: 'post', data });
    return result;
  };

  static editCasePay = async data => {
    const result = await edit({ url: requestUrl, method: 'put', data });
    return result;
  };
}
