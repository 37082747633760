import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import {
  getClinicLabOrderAPI,
  getClinicLaboratoriesAPI,
  getClinicProgressAPI,
  getTreatmentCategoryAPI,
  getDoctorAPI,
  getCardInfo,
} from '../utils/utils';

const initOptions = {
  datePicked: {
    progress: 'estimatedReturnDate',
    dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
  },
  laboratory: 'all',
  doctor: 'all',
  progress: { _id: 'all' },
  patient: null,
  treatmentCategory: 'all',
  labOrder: null,
  skipArchivedData: true,
};

const tableSlice = createSlice({
  name: 'homePage/table',
  initialState: {
    data: [],
    selectedRows: [],
    laboratories: [],
    progressList: [],
    treatmentCategoryList: [],
    doctorList: [],
    isLoading: false,
    isAdvanceOpen: false,
    selectedProgress: initOptions.datePicked.progress,
    options: initOptions,
    cardInfo: {},
  },

  reducers: {
    setLoadingStart: state => {
      return {
        ...state,
        isLoading: true,
      };
    },

    setLoadingEnd: state => {
      return {
        ...state,
        isLoading: false,
      };
    },

    toggleAdvanceOpen: state => {
      return {
        ...state,
        isAdvanceOpen: !state.isAdvanceOpen,
      };
    },

    setData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },

    setOptions: (state, action) => {
      return {
        ...state,
        options: action.payload,
      };
    },

    setLaboratory: (state, action) => {
      return {
        ...state,
        laboratories: action.payload,
      };
    },

    setProgressList: (state, action) => {
      return {
        ...state,
        progressList: action.payload,
      };
    },

    setTreatmentCategory: (state, action) => {
      return {
        ...state,
        treatmentCategoryList: action.payload,
      };
    },

    setDoctorList: (state, action) => {
      return {
        ...state,
        doctorList: action.payload,
      };
    },

    setSelectedProgress: (state, action) => {
      return {
        ...state,
        selectedProgress: action.payload,
      };
    },

    setCardInfo: (state, action) => {
      return {
        ...state,
        cardInfo: action.payload,
      };
    },

    resetOptions: state => {
      return {
        ...state,
        selectedProgress: initOptions.datePicked.progress,
        options: initOptions,
      };
    },

    updateSelectedRows: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload,
      };
    },
  },
});

export const {
  setData,
  setOptions,
  setIsLoading,
  setLoadingStart,
  setLoadingEnd,
  setLaboratory,
  setProgressList,
  setTreatmentCategory,
  setDoctorList,
  setSelectedProgress,
  setCardInfo,
  resetOptions,
  updateSelectedRows,
  toggleAdvanceOpen,
} = tableSlice.actions;

export const homepageInit = urlQuery => async (dispatch, getState) => {
  dispatch(setLoadingStart());
  Promise.all([getClinicLaboratoriesAPI(), getClinicProgressAPI(), getTreatmentCategoryAPI(), getDoctorAPI()]).then(
    ([laboratories, progressList, treatmentCategories, doctors]) => {
      dispatch(setLaboratory(laboratories));
      dispatch(setProgressList(progressList));
      dispatch(setTreatmentCategory(treatmentCategories));
      dispatch(setDoctorList(doctors));
      dispatch(setOptionsByUrl(urlQuery));
      const { options } = getState().homePageReducer.table;
      getCardInfo(progressList).then(cardInfo => dispatch(setCardInfo(cardInfo)));
      getClinicLabOrderAPI(options).then(labOrder => {
        dispatch(setSelectedProgress(options.datePicked.progress));
        dispatch(setData(labOrder));
      });
      dispatch(setLoadingEnd());
    },
  );
};

export const searchDashBoard = () => (dispatch, getState) => {
  dispatch(setLoadingStart());
  const { options } = getState().homePageReducer.table;
  getClinicLabOrderAPI(options)
    .then(labOrder => {
      dispatch(setSelectedProgress(options.datePicked.progress));
      dispatch(setData(labOrder));
    })
    .finally(() => {
      dispatch(setLoadingEnd());
    });
};

export const searchCardCase = options => (dispatch, getState) => {
  dispatch(setLoadingStart());
  const { progressList } = getState().homePageReducer.table;
  Promise.all([getClinicLabOrderAPI(options), getCardInfo(progressList)])
    .then(([orders, cardInfo]) => {
      dispatch(setSelectedProgress(options.datePicked.progress));
      dispatch(setData(orders));
      dispatch(setCardInfo(cardInfo));
    })
    .finally(() => {
      dispatch(setLoadingEnd());
    });
};

export const resetDashBoard = () => (dispatch, getState) => {
  const options = getState().homePageReducer.table.options;
  dispatch(setLoadingStart());
  getClinicLabOrderAPI(options)
    .then(result => {
      dispatch(setData(result));
    })
    .finally(() => {
      dispatch(setLoadingEnd());
    });
};

export const setOptionsByUrl = urlParams => dispatch => {
  const params = Object.fromEntries(urlParams);
  if (_.isEmpty(params)) return dispatch(setOptions(initOptions));

  const {
    datePickedProgress,
    datePickedFrom,
    datePickedTo,
    laboratory,
    doctor,
    progressId,
    progressName,
    progressOrder,
    patient,
    treatmentCategory,
    labOrder,
    skipArchivedData,
  } = params;

  dispatch(
    setOptions({
      datePicked: { progress: datePickedProgress, dateRange: [datePickedFrom, datePickedTo] },
      laboratory,
      doctor,
      progress: { _id: progressId, name: progressName, order: progressOrder },
      patient: patient || '',
      treatmentCategory,
      labOrder: labOrder || '',
      skipArchivedData: skipArchivedData === 'true',
    }),
  );
};

export default tableSlice.reducer;
