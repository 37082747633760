import { createSlice } from '@reduxjs/toolkit';

const casePaySlice = createSlice({
  name: 'settingPage/casePay',
  initialState: {
    casePay: [],
    editing: {
      _id: '',
      name: '',
      isCommonly: false,
    },
    deleting: {
      _id: '',
      name: '',
    },
    loading: false,
  },
  reducers: {
    getCasePayStart: state => {
      state.loading = true;
    },
    getCasePay: (state, action) => {
      state.casePay = action.payload;
      state.loading = false;
    },
    editingCasePay: (state, action) => {
      state.editing = { ...state.editing, ...action.payload };
    },
    resetForm: state => {
      state.editing._id = '';
      state.editing.name = '';
      state.editing.isCommonly = false;
    },
    selectCasePayItem: (state, action) => {
      const current = action.payload;
      state.editing.name = current.name;
      state.editing._id = current._id;
      state.editing.isCommonly = current.isCommonly;
    },
    getDeleteTarget: (state, action) => {
      state.deleting._id = action.payload._id;
      state.deleting.name = action.payload.name;
    },
    resetDeleteForm: state => {
      state.deleting._id = '';
      state.deleting.name = '';
    },
    updateOriginalCasePay: (state, action) => {
      state.casePay = state.casePay.map(pay => {
        if (pay._id === action.payload._id) {
          return action.payload;
        }
        return pay;
      });
    },
    filterDeleteData: (state, action) => {
      state.casePay = state.casePay.filter(pay => pay._id !== action.payload._id);
    },
  },
});

export const {
  getCasePayStart,
  getCasePay,
  selectCasePayItem,
  getDeleteTarget,
  updateOriginalCasePay,
  editingCasePay,
  resetForm,
  resetDeleteForm,
  filterDeleteData,
} = casePaySlice.actions;
export default casePaySlice.reducer;
