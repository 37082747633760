import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import settingReducer from './settingSlice';
import tableReducer from './tableSlice';
import patientReducer from './patientDrawerSlice';
import errorCenterReducer from './errorCenterSlice';
import workspaceReducer from './workspaceSlice';

const homePageReducer = combineReducers({
  authentication: authReducer,
  setting: settingReducer,
  table: tableReducer,
  patient: patientReducer,
  errorCenter: errorCenterReducer,
  ws: workspaceReducer,
});

export default homePageReducer;
