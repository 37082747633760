export default function toCaseProgress(progress) {
  return progress
    .sort((a, b) => a.priority - b.priority)
    .map(p => {
      return {
        _id: p._id,
        name: p.name,
        status: 'UNSELECTED',
        completedDate: p.completedDate || null,
        operator: p.operator || '',
      };
    });
}
