import styled from 'styled-components';

//#region
const RequiredIconContainer = styled.span`
  font-size: 13px;
  color: var(--default-500-danger);
  padding-left: 3px;
`;
//#endregion

const RequiredIcon = () => {
  return <RequiredIconContainer>*</RequiredIconContainer>;
};

export default RequiredIcon;
