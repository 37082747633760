import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { baseWsApi } from './services/ws/wsApi';
import homePageReducer from './container/HomePage/slice';
import casePageReducer from './container/CasePage/slice';
import settingPageReducer from './container/SettingPage/slice';
import sharedPageReducer from './container/SharedPage/slice';

export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    [baseWsApi.reducerPath]: baseWsApi.reducer,
    homePageReducer,
    casePageReducer,
    settingPageReducer,
    sharedPageReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseWsApi.middleware),
  devTools: process.env.REACT_APP_ENV === 'dev',
});
