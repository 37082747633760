import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Input } from 'antd';
import { Controller } from 'react-hook-form';
import { VerticalInput } from '../../../component/Input';
import CustomizedAlert from '../../../component/Alert';
import { RequiredIcon } from '../../../component/Display';
import { AlertMessage } from '../../../component/Display';

const { TextArea } = Input;
//#region
const DeleteFormContainer = styled.div`
  .modal__delete-description {
    font-size: 15px;
    margin-bottom: 1.25em;
  }
  .modal__delete-form {
    & > div {
      margin-bottom: 1.25em;
    }
  }
`;
const DeleteReason = styled.div`
  label {
    font-size: 13px;
  }
`;
const StyledTextArea = styled(TextArea)`
  resize: none;
  margin-top: 3px;
  font-size: 16px;
  ${props => props.alert && `border: 1px solid var(--default-500-danger);`}
`;
//#endregion

const DeleteForm = ({ control, error, alert }) => {
  const { formatMessage: f } = useIntl();
  return (
    <DeleteFormContainer>
      {alert && <CustomizedAlert message={f({ id: 'alert.error.message' })} type={'error'} showIcon={true} />}
      <div className='modal__delete-description'>{f({ id: 'modal.delete.description' })}</div>
      <div className='modal__delete-form'>
        <VerticalInput
          required={true}
          alertMessage={'form.alert'}
          label={'modal.delete.form.person'}
          placeholder={'input.placeholder'}
          size={'large'}
          validationName={'person'}
          control={control}
          error={error}
        />
        <DeleteReason>
          <label>
            {f({ id: 'modal.delete.form.reason' })}
            <RequiredIcon />
            <Controller
              name={'reason'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange }, field }) => (
                <StyledTextArea
                  {...field}
                  placeholder={f({ id: 'input.placeholder' })}
                  alert={!!error.reason ? 'alert' : undefined}
                  onChange={e => onChange(e.target.value)}
                />
              )}
            />
          </label>
          {error.reason && <AlertMessage />}
        </DeleteReason>
      </div>
    </DeleteFormContainer>
  );
};

export default DeleteForm;
