import { Spin as AntSpin } from 'antd';
import styled from 'styled-components';

//#region
export const StyledSpin = styled(AntSpin)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
//#endregion

export default StyledSpin;
