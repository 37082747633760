import React, { useState, forwardRef } from 'react';
import { Input, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RequiredIcon from '../Display/RequiredIcon';
import { AlertMessage } from '../Display';

//#region
const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 13px;
  }
  .ant-checkbox {
    margin-top: 3px;
  }
`;
const PasswordInput = styled(Input)`
  margin-top: 3px;
  ${props => props.$alert && `border: 1px solid var(--default-500-danger);`}
  ::placeholder {
    ${props => (props.size !== 'large' ? `font-size: 13px;` : `font-size: 15px;`)}
  }
`;
//#endregion

const Password = forwardRef(({ value, changeHandler, size, allowClear, disabled, maxLength, alert, fail }, ref) => {
  const [checked, setChecked] = useState(false);
  const { formatMessage: f } = useIntl();

  return (
    <PasswordContainer>
      <label>
        {f({ id: 'case.delete.password' })}
        {<RequiredIcon />}
        <PasswordInput
          value={value}
          onChange={e => changeHandler(e.target.value)}
          placeholder={f({ id: 'input.placeholder' })}
          size={size}
          allowClear={allowClear}
          disabled={disabled}
          maxLength={maxLength}
          type={checked ? 'text' : 'password'}
          $alert={alert || fail}
          ref={ref}
        />
      </label>
      {fail && <AlertMessage message='password.error' />}
      {alert && <AlertMessage />}
      <Checkbox onChange={() => setChecked(!checked)} checked={checked}>
        {f({ id: 'password.visible' })}
      </Checkbox>
    </PasswordContainer>
  );
});

export default Password;

Password.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  alert: PropTypes.bool,
};

Password.defaultTypes = {
  value: '',
  changeHandler: () => {},
  size: 'middle',
  allowClear: true,
  disabled: false,
  maxLength: undefined,
  alert: false,
};
