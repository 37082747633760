import { errorStatus } from '@/constants/errorStatus';
const parseErrorContent = (data, f) => {

  switch (data?.code) {
    case errorStatus.ERR_WS_UNAUTHENTICATED:
    case errorStatus.ERR_WS_UNAUTHENTICATED_AND_UNAUTHORIZED:
      return {
        errorTitle: data.title,
        errorDescription: data.content,
        errorCode: '401',
        showLogout: true
      };
    case errorStatus.ERR_WS_MEMBER_UNAUTHORIZED:
      return {
        errorTitle: data.title,
        errorDescription: data.content,
        errorCode: '403',
        showLogout: true
      };
    case errorStatus.ERR_SHEETA_0006:
      return {
        errorTitle: f({id: 'error.auth.title'}),
        errorDescription: f({ id: 'error.auth.description' }),
        errorCode: '403',
        showLogout: true
      };
    case errorStatus.ERR_SHEETA_0002:
    default:
      return {
        errorTitle: f({id: 'notFound.title'}),
        errorDescription: f({id: 'notFound.description'}),
        errorCode: '404',
        showLogout: false
      };
  }
};

export default parseErrorContent;
