import { Checkbox } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import CountingInput from '../../../component/Input/CountingInput';
import { RequiredIcon } from '../../../component/Display';
import { AlertMessage } from '../../../component/Display';

//#region
const CaseItemFormContainer = styled.div`
  & > div {
    padding-bottom: 30px;
    .ant-checkbox {
      margin-right: 8px;
    }
  }
`;
//#endregion

const CaseItemCountingForm = ({ control, error, changeName, changeCommonly, value, validate }) => {
  const { formatMessage: f } = useIntl();
  return (
    <CaseItemFormContainer>
      <div>
        <label>
          {f({ id: 'labSetting.form.name' })}
          <RequiredIcon />
          <Controller
            name={'name'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange }, field }) => (
              <CountingInput
                {...field}
                required={true}
                alertMessage={'form.alert'}
                label={'labSetting.form.name'}
                placeholder={'input.placeholder'}
                size={'large'}
                changeHandler={value => {
                  onChange(value);
                  changeName(value);
                }}
                value={value.name}
                alert={!!error.name || validate}
                limit={30}
              />
            )}
          />
        </label>
        {error.name && <AlertMessage margin='0' />}
        {validate && <AlertMessage margin='0' message='error.duplicate.name' />}
      </div>
      <div>
        <label>
          <Checkbox onChange={changeCommonly} checked={value.isCommonly} />
          {f({ id: 'labSetting.form.mark' })}
        </label>
      </div>
    </CaseItemFormContainer>
  );
};

export default CaseItemCountingForm;
