import { edit, get } from '../utils/api';

const requestUrl = `/treatmentCategory`;

export default class CaseType {
  static getCaseType = async () => {
    const result = await get(requestUrl);
    return result;
  };

  static getCaseTypeById = async params => {
    const result = await get(`${requestUrl}/${params}`);
    return result;
  };

  static createCaseType = async data => {
    const result = await edit({ url: requestUrl, method: 'post', data });
    return result;
  };

  static editCaseType = async data => {
    const result = await edit({ url: requestUrl, method: 'put', data });
    return result;
  };
}
