import { createSlice } from '@reduxjs/toolkit';

const patientDrawerSlice = createSlice({
  name: 'homePage/patient',
  initialState: {
    drawerOpen: false,
    searchedText: '',
    total: [],
    name: [],
    phone: [],
    birth: [],
    mrn: [],
    nationalId: [],
    registrationPatient: [],
  },
  reducers: {
    changeDrawerVisible: (state, action) => {
      state.drawerOpen = action.payload;
      if (!action.payload) {
        state.total = [];
        state.name = [];
        state.phone = [];
        state.birth = [];
        state.mrn = [];
        state.nationalId = [];
        state.searchedText = '';
      }
    },
    searchPatientSuccess: (state, action) => {
      const name = action.payload[0] || [];
      const birth = action.payload[4] || [];
      const phone = action.payload[1] || [];
      const mrn = action.payload[3] || [];
      const nationalId = action.payload[2] || [];
      state.name = name;
      state.birth = birth;
      state.phone = phone;
      state.mrn = mrn;
      state.nationalId = nationalId;
      state.total = [...name, ...phone, ...birth, ...mrn, ...nationalId].filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i,
      );
      state.searchedText = action.payload.searchText;
    },
    getRegistrationPatientSuccess: (state, action) => {
      state.total = (action.payload && action.payload.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)) || [];
      state.name = [];
      state.phone = [];
      state.birth = [];
      state.mrn = [];
      state.nationalId = [];
    },
  },
});

export const { changeDrawerVisible, searchPatientSuccess, getRegistrationPatientSuccess } = patientDrawerSlice.actions;
export default patientDrawerSlice.reducer;
