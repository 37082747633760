import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';
import { appInit } from './utils/appInit';

const authReSelector = createSelector(
  state => state.homePageReducer.authentication,
  ({ is_ok, currentUser, appInit }) => ({
    is_ok,
    isFetching: appInit.app_init,
    currentUser,
  }),
);

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search = '' } = useLocation();
  const [searchParams] = useSearchParams();
  const st = searchParams.get('st') || '';

  useEffect(() => {
    dispatch(appInit({ search, st, navigate }));
  }, [dispatch, search, st, navigate]);

  return useSelector(authReSelector);
};
