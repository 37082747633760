import moment from 'moment';
import LabSetting from '../../../models/labSetting';
import CaseType from '../../../models/caseType';
import Progress from '../../../models/progress';
import Doctors from '../../../models/doctors';
import Case from '../../../models/case';
import findProgress from './findProgress';

export const getClinicLabOrderAPI = options => {
  return Case.getCaseByOptions(options)
    .then(result => result)
    .catch(error => console.log(error));
};

export const getClinicLaboratoriesAPI = () => {
  return LabSetting.getLabSetting()
    .then(result => result)
    .catch(error => console.log(error));
};

export const getClinicProgressAPI = () => {
  return Progress.getProgress()
    .then(result => result)
    .catch(error => console.log(error));
};

export const getTreatmentCategoryAPI = () => {
  return CaseType.getCaseType()
    .then(result => result)
    .catch(error => console.log(error));
};

export const getDoctorAPI = () => {
  return Doctors.getDoctors()
    .then(result => result)
    .catch(error => console.log(error));
};

export const getCardInfo = progressList => {
  return Promise.all([
    Case.getCaseAmount(getCardOptions(progressList, 'unReturnCase')),
    Case.getCaseAmount(getCardOptions(progressList, 'unSubmitCase')),
  ])
    .then(([unReturnAmount, unSubmitAmount]) => {
      return { unReturnAmount, unSubmitAmount };
    })
    .catch(error => console.log(error));
};

export const findLastDoneProgress = (progressPackage = []) => {
  const order = progressPackage.map(progress => progress.status).lastIndexOf('COMPLETED');
  if (order === -1) return null;
  return progressPackage[order];
};

export const getCardOptions = (progressList, card) => {
  const cardOptions = {
    laboratory: 'all',
    doctor: 'all',
    patient: null,
    treatmentCategory: 'all',
    labOrder: null,
    skipArchivedData: true,
  };
  if (card === 'unReturnCase') {
    const { progress: initProgress, index } = findProgress(progressList, '送出模型');
    return {
      ...cardOptions,
      datePicked: {
        dateRange: [moment().subtract(365, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        progress: 'estimatedReturnDate',
      },
      progress: { ...initProgress, order: index },
    };
  }
  if (card === 'unSubmitCase') {
    const { progress: initProgress, index } = findProgress(progressList, '印模');
    return {
      ...cardOptions,
      datePicked: {
        dateRange: [moment().subtract(365, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        progress: initProgress?._id,
      },
      progress: { ...initProgress, order: index },
    };
  }
};

export const generateSearchParams = options => {
  const { datePicked, laboratory, progress, doctor, patient, treatmentCategory, labOrder, skipArchivedData } = options;
  return {
    datePickedProgress: datePicked?.progress,
    datePickedFrom: datePicked?.dateRange[0],
    datePickedTo: datePicked?.dateRange[1],
    laboratory,
    doctor,
    progressId: progress?._id,
    progressName: progress?.name,
    progressOrder: progress?.order,
    patient,
    treatmentCategory,
    labOrder,
    skipArchivedData,
  };
};
