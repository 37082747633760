export const splitArchived = (arr = []) => {
  let normal = [];
  let archived = [];
  if (arr.length === 0) {
    return { normal, archived };
  }
  arr.forEach(a => {
    if (a.isArchived) {
      return archived.push(a);
    }
    normal.push(a);
  });
  return { normal, archived };
};
