import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ReactComponent as EmptyIcon } from '../../images/empty.svg';

//#region
const EmptyContainer = styled.div`
  width: 100%;
  height: inherit;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--default-500-hint);
    font-size: 15px;
  }
  svg {
    margin-bottom: 1.25em;
  }
`;
//#endregion

const Empty = ({ description }) => {
  const { formatMessage: f } = useIntl();
  return (
    <EmptyContainer>
      <div>
        <EmptyIcon />
        {description && <div>{f({ id: description })}</div>}
      </div>
    </EmptyContainer>
  );
};

export default Empty;

Empty.propTypes = {
  description: PropTypes.string,
};

Empty.defaultProps = {
  description: '',
};
