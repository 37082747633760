export default function validateLimit(text = '', limit) {
  // eslint-disable-next-line no-control-regex
  const re = /^[^\x00-\x7F]$/;
  const arr = text.split('');
  let current = 0;
  let index = 1;

  for (let i = 0; i < arr.length; i++) {
    re.test(arr[i]) ? (current += 2) : (current += 1);
    index = i + 1;
    if (current >= limit) {
      break;
    }
  }
  return { value: current >= limit ? text.slice(0, index) : text, count: current > limit ? limit : current };
}
