import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Input from './Input';
import { Controller } from 'react-hook-form';
import RequiredIcon from '../Display/RequiredIcon';
import { AlertMessage } from '../Display';

//#region
const VerticalInputContainer = styled.div`
  label {
    font-size: 13px;
  }
`;
//#endregion

const VerticalInput = ({
  label,
  value,
  changeHandler,
  placeholder,
  size,
  disabled,
  validationName,
  control,
  error,
  required,
  alertMessage,
  validateError,
}) => {
  const { formatMessage: f } = useIntl();
  if (required) {
    return (
      <VerticalInputContainer>
        <label>
          {f({ id: label })}
          {required && <RequiredIcon />}
        </label>
        <Controller
          name={validationName}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              size={size}
              alert={!_.isEmpty(error[validationName]) || validateError}
              disabled={disabled}
              placeholder={f({ id: placeholder })}
              onChange={e => {
                onChange(e.target.value);
                changeHandler && changeHandler(e.target.value);
              }}
              value={value}
            />
          )}
        />
        {error[validationName] && <AlertMessage margin='0' message={alertMessage} />}
        {validateError && <AlertMessage margin='0' message='error.duplicate.name' />}
      </VerticalInputContainer>
    );
  }
  return (
    <VerticalInputContainer>
      <label>
        {f({ id: label })}
        <Input
          value={value}
          onChange={changeHandler}
          placeholder={f({ id: placeholder })}
          size={size}
          disabled={disabled}
        />
      </label>
    </VerticalInputContainer>
  );
};

export default VerticalInput;

VerticalInput.propTypes = {
  required: PropTypes.bool,
  alertMessage: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  isAlert: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  control: PropTypes.object,
  error: PropTypes.object,
};

VerticalInput.defaultTypes = {
  required: false,
  alertMessage: 'form.alert',
  label: '',
  value: '',
  changeHandler: () => {},
  placeholder: 'input.placeholder',
  isAlert: false,
  size: 'large',
  disabled: false,
  control: undefined,
  error: {},
};
