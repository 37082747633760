import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getCaseSuccess, updateCase, updateLab } from './caseSlice';
import sortByUpdateTime from '../utils/sortByUpdateTime';
import { resetValidateEmpty, validateEmpty } from './teethSlice';

const caseSettingSlice = createSlice({
  name: 'casePage/caseSetting',
  initialState: {
    progress: [],
    doctors: [],
    lab: [],
    caseType: [],
    paymentMethod: [],
    caseLi: [],
    loading: false,
    caseList: [],
    caseLoading: false,
    patient: {},
    caseError: {
      doctorData: false,
      lab: false,
      manufactureProject: false,
      estimatedReturnDate: false,
    },
    errorId: false,
    originalCase: {},
  },
  reducers: {
    initializeStart: state => {
      state.loading = true;
    },
    initializeSuccess: (state, action) => {
      state.loading = false;
      state.progress = action.payload.progress;
      state.doctors = action.payload.doctors;
      state.lab = action.payload.lab;
      state.caseType = action.payload.caseType;
      state.paymentMethod = action.payload.paymentMethod;
      state.caseLi = action.payload.caseLi;
    },
    getPatientSuccess: (state, action) => {
      state.patient = action.payload;
    },
    getCaseListByPatientStart: state => {
      state.caseLoading = true;
    },
    getCaseListByPatientSuccess: (state, action) => {
      state.caseLoading = false;
      state.caseList = sortByUpdateTime(action.payload);
    },
    addCaseList: (state, action) => {
      state.caseList.unshift(action.payload);
    },
    filterDeletedCase: (state, action) => {
      state.caseList = state.caseList.filter(c => c._id !== action.payload);
    },
    setErrorId: (state, action) => {
      state.errorId = action.payload;
    },
    updateOriginalCase: (state, action) => {
      state.originalCase = action.payload;
    },
  },
  extraReducers: {
    [validateEmpty](state, action) {
      state.caseError.doctorData = _.isEmpty(action.payload.doctor);
      state.caseError.lab = _.isEmpty(action.payload.lab);
      state.caseError.manufactureProject = _.isEmpty(action.payload.project);
      state.caseError.estimatedReturnDate = _.isEmpty(action.payload.returnDate);
    },
    [resetValidateEmpty](state, action) {
      state.caseError.doctorData = action.payload;
      state.caseError.lab = action.payload;
      state.caseError.manufactureProject = action.payload;
      state.caseError.estimatedReturnDate = action.payload;
    },
    [updateCase](state, action) {
      if (Object.keys(state.caseError).includes(Object.keys(action.payload)[0])) {
        state.caseError[Object.keys(action.payload)] = _.isEmpty(action.payload[Object.keys(action.payload)]);
      }
    },
    [updateLab](state, action) {
      state.caseError.lab = _.isEmpty(action.payload);
    },
    [getCaseSuccess](state) {
      state.caseError.doctorData = false;
      state.caseError.lab = false;
      state.caseError.manufactureProject = false;
      state.caseError.estimatedReturnDate = false;
    },
    [getCaseSuccess](state, action) {
      state.originalCase = action.payload;
    },
  },
});

export const {
  initializeStart,
  initializeSuccess,
  getCaseListByPatientStart,
  getCaseListByPatientSuccess,
  addCaseList,
  getPatientSuccess,
  passwordValidation,
  filterDeletedCase,
  resetPasswordFail,
  setErrorId,
  updateOriginalCase,
} = caseSettingSlice.actions;

export default caseSettingSlice.reducer;
