import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useDevice = () => {
  const [detectTouchscreen, setDetectTouchscreen] = useState(false);

  const isTabletAndMobile = useMediaQuery({ maxWidth: 1279 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1280 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const TabletAndMobile = ({ children }) => (isTabletAndMobile ? children : null);
  const DesktopOrLaptop = ({ children }) => (isDesktopOrLaptop ? children : null);
  const Tablet = ({ children }) => (isTablet ? children : null);
  const Mobile = ({ children }) => (isMobile ? children : null);

  function detectTouchscreenHandler() {
    let hasTouchScreen = false;

    // if Pointer Events are supported, just check maxTouchPoints
    if (window.PointerEvent && 'maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    }
    if ('msMaxTouchPoints' in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    }

    // no Pointer Events...
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
      // check for any-pointer:coarse which mostly means touchscreen
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return setDetectTouchscreen(hasTouchScreen);
  }

  useEffect(() => {
    detectTouchscreenHandler();
  }, []);

  return {
    TabletAndMobile,
    DesktopOrLaptop,
    Tablet,
    Mobile,
    detectTouchscreen,
    isDesktopOrLaptop,
    isTabletAndMobile,
    isTablet,
    isMobile,
  };
};
