import { Checkbox } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { VerticalInput } from '../../../component/Input';

//#region
const CaseItemFormContainer = styled.div`
  & > div {
    padding-bottom: 30px;
    .ant-checkbox {
      margin-right: 8px;
    }
  }
`;
//#endregion

const CaseItemForm = ({ control, error, changeName, changeCommonly, value, validate }) => {
  const { formatMessage: f } = useIntl();

  return (
    <CaseItemFormContainer>
      <VerticalInput
        required={true}
        alertMessage={'form.alert'}
        label={'labSetting.form.name'}
        placeholder={'input.placeholder'}
        size={'large'}
        control={control}
        error={error}
        validationName={'name'}
        changeHandler={changeName}
        value={value.name}
        validateError={validate}
      />
      <div>
        <label>
          <Checkbox onChange={changeCommonly} checked={value.isCommonly} />
          {f({ id: 'labSetting.form.mark' })}
        </label>
      </div>
    </CaseItemFormContainer>
  );
};

export default CaseItemForm;
