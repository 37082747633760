import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LabSettingHeader from './component/LabSettingHeader';
import CaseItemContent from './component/CaseItemContent';
import { Button } from '../../component/Button';
import { ReactComponent as PlusIcon } from '../../images/icons-plus.svg';
import { ReactComponent as PlusDisableIcon } from '../../images/icon-his-icons-plus-disable.svg';
import { CaseItemsContainer } from './CaseType';

const CaseLi = () => {
  const { caseCategory } = useSelector(state => state.settingPageReducer.caseLi);
  const [categoryDrawerVisible, setCategoryDrawerVisible] = useState(false);
  const [itemDrawerVisible, setItemDrawerVisible] = useState(false);
  const [sort, setSort] = useState('name');

  const handleCategoryVisible = () => setCategoryDrawerVisible(true);
  const handleCategoryInvisible = () => setCategoryDrawerVisible(false);
  const handleItemDrawerVisible = () => setItemDrawerVisible(true);
  const handleItemDrawerInvisible = () => setItemDrawerVisible(false);

  return (
    <CaseItemsContainer>
      <LabSettingHeader
        title='caseLi.title'
        sortHandler={setSort}
        sortValue={sort}
        className={'l-caseLi-header'}
        content={
          <div className='settingTitle-content l-caseLi-header__content'>
            <Button
              type='primary'
              width={'107px'}
              icon={<PlusIcon />}
              onClick={handleCategoryVisible}
              buttonText={'caseLi.button.addCategory'}
            />
            <Button
              type='primary'
              width={'107px'}
              icon={caseCategory.length === 0 ? <PlusDisableIcon /> : <PlusIcon />}
              onClick={handleItemDrawerVisible}
              buttonText={'caseLi.button.addItem'}
              isDisable={caseCategory.length === 0}
            />
          </div>
        }
      />
      <CaseItemContent
        categoryDrawerVisible={categoryDrawerVisible}
        itemDrawerVisible={itemDrawerVisible}
        handleCategoryVisible={handleCategoryVisible}
        handleCategoryInvisible={handleCategoryInvisible}
        handleItemDrawerVisible={handleItemDrawerVisible}
        handleItemDrawerInvisible={handleItemDrawerInvisible}
        sort={sort}
      />
    </CaseItemsContainer>
  );
};

export default CaseLi;
