import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'homePage/authentication',
  initialState: {
    appInit: {},
    is_ok: false,
    currentUser: undefined,
    isLogin: false,
  },
  reducers: {
    appInitFetching: (state, action) => {
      return {
        ...state,
        appInit: {...state.appInit, ...action.payload},
      };
    },
    authenticated: (state, action) => {
      return {
        ...state,
        is_ok: action.payload,
      };
    },
    updateCurrentUser: (state, action) => {
      return {
        ...state,
        isLogin: true,
        currentUser: action?.payload,
      };
    },
  },
});

export const { authenticated, updateCurrentUser, appInitFetching } = authSlice.actions;

export default authSlice.reducer;
