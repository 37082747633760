import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AutoComplete as AntAutoComplete } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { autoCompleteInput } from '../../utils/autoComplete';

//#region
const CustomAutoComplete = styled(AntAutoComplete)`
  ${props =>
    props.$alert &&
    `
    &:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid var(--default-500-danger);
    }
`}
`;
//#endregion

const { Option } = AntAutoComplete;
const AutoComplete = ({ value, placeholder, onChange, alert, clinicId, size, indicesId }) => {
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState(value);

  useEffect(() => {
    let unmounted = false;
    autoCompleteInput(query, clinicId, indicesId, ({ hits }) => {
      if (!unmounted) {
        setOptions(hits);
      }
    });
    return () => {
      unmounted = true;
    };
  }, [query, clinicId, indicesId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce = useCallback(
    _.debounce(value => setQuery(value), 200),
    [],
  );

  return (
    <CustomAutoComplete
      size={size}
      placeholder={placeholder}
      onChange={value => onChange(value)}
      value={value}
      onSearch={value => debounce(value)}
      filterOption={false} // ant can filter twice
      $alert={alert}
    >
      {options.map(({ project, objectID }) => (
        <Option key={objectID} value={project}>
          {project}
        </Option>
      ))}
    </CustomAutoComplete>
  );
};

export default AutoComplete;

AutoComplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  alert: PropTypes.bool,
  size: PropTypes.string,
  clinic_id: PropTypes.string,
};

AutoComplete.defaultTypes = {
  value: '',
  onChange: () => {},
  placeholder: '',
  alert: false,
  size: 'large',
  clinic_id: '',
};
