import _ from "lodash";
import appendQuery from 'append-query';
import { wsUrl } from '@/config';

export const redirectToWsList = () => {
  return redirectHrefTo(appendStr(`${wsUrl}/list`, { ecUrl: getHref() }));
};
export const redirectHrefTo = (href) => {
  if (_.isEmpty(href)) {
    window.location.href = '/';
  } else {
    window.location.href = href;
  }
};
export const appendStr = (oriStr, appendQ) => {
  return appendQuery(oriStr, { ...appendQ });
}
export const getHref = () => {
  return window.location.href;
};
