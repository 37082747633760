import { combineReducers } from '@reduxjs/toolkit';
import teethReducer from './teethSlice';
import caseReducer from './caseSlice';
import caseSettingReducer from './caseSettingSlice';
import shareReducer from './shareSlice';

const casePageReducer = combineReducers({
  teeth: teethReducer,
  case: caseReducer,
  caseSetting: caseSettingReducer,
  share: shareReducer,
});

export default casePageReducer;
