import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import commentReducer from './commentSlice';
import caseReducer from './caseSlice';
import lightBoxReducer from './lightBoxSlice';

const sharedPageReducer = combineReducers({
  authentication: authReducer,
  comments: commentReducer,
  case: caseReducer,
  lightBox: lightBoxReducer,
});

export default sharedPageReducer;
