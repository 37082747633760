import { Avatar as AntAvatar } from 'antd';
import PropTypes from 'prop-types';
import maleAvatar from '../../images/4-data-display-7-avatar-man@2x.png';
import femaleAvatar from '../../images/4-data-display-7-avatar-woman@2x.png';
import defaultAvatar from '../../images/4-data-display-7-avatar-default@2x.svg';

const Avatar = ({ size, shape, type, icon, gender, image }) => {
  const renderType =
    type === 'icon'
      ? {
          icon: icon,
        }
      : type === 'img'
      ? {
          src: `${image ? image : gender === 'MALE' ? maleAvatar : gender === 'FEMALE' ? femaleAvatar : defaultAvatar}`,
        }
      : null;
  return <AntAvatar size={size} shape={shape} {...renderType} />;
};

export default Avatar;

Avatar.propTypes = {
  size: PropTypes.number,
  shape: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.element,
  gender: PropTypes.string,
  image: PropTypes.string,
};

Avatar.defaultProps = {
  size: 64,
  shape: 'round',
  type: 'img',
  icon: undefined,
  gender: undefined,
  image: '',
};
