import React, { forwardRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ReactComponent as MenuIcon } from '../../../images/icon-his-icons-menu-black.svg';
import { MoreButton } from '../../../component/Button';
import circle from '../../../images/oval-copy.svg';
import line from '../../../images/line-2-copy.svg';

//#region
const StyledDragItem = styled.div`
  background-color: var(--transparent-fill-1-primary-24);
  width: 100%;
  padding: 11px 24px;
  color: var(--default-500-light);

  &.sortable {
    width: 100%;
    padding: 11px 24px;
    position: relative;
    background-color: var(--default-500-light);
    touch-action: manipulation;
    color: var(--dark);
    border-bottom: 1px solid var(--border-bottom);
    &:first-child {
      margin-top: 3px;
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: var(--basic-fill-02-200);
      .caseDragList-more {
        visibility: visible;
        opacity: 1;
      }
    }
    &.selected {
      background-color: var(--color-fill-1-primary-700);
      color: var(--default-500-light);
    }
    .caseDragList-more {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.1s ease;
      &:focus {
        visibility: visible;
        opacity: 1;
      }
    }
    .dragItem__content svg > g > g {
      fill: var(--dark);
    }

    &.dragging {
      background-color: var(--transparent-fill-1-primary-32);
    }
    &.insertBefore,
    &.insertAfter {
      &:after {
        left: 8px;
        right: 0;
        height: 6px;
        content: '';
        position: absolute;
        background-image: url(${circle}), url(${line});
        background-repeat: no-repeat;
        background-size: contain, 150%;
        z-index: 2;
        background-position-y: 0, 2px;
        background-position-x: 0, 3px;
      }
    }
    &.insertAfter:after {
      bottom: -4px;
    }
    &.insertBefore:after {
      top: -4px;
    }
  }

  .dragItem__content {
    touch-action: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    svg > g > g {
      fill: var(--default-500-light);
    }
    span:first-child {
      margin-right: 24px;
      display: flex;
    }
    & > div > div {
      word-break: break-all;
    }
    button {
      margin-left: 17px;
    }
    &.dragging,
    &.selected {
      color: var(--default-500-light);
      svg > g > g {
        fill: var(--default-500-light);
      }
    }
    .dragList__archived {
      color: var(--default-500-hint);
      font-style: italic;
      line-height: 15px;
    }
    .dragList__archivedAlert {
      font-size: 12px;
    }
  }
`;
//#endregion

export const Item = forwardRef(
  (
    {
      data,
      listeners,
      className,
      switchList,
      isDragging,
      activate,
      archive,
      mark,
      onEdit,
      onDelete,
      isSelected,
      selectCategory,
      ...props
    },
    ref,
  ) => {
    const { formatMessage: f } = useIntl();
    return (
      <StyledDragItem className={className} onClick={() => selectCategory(data._id)} ref={ref}>
        <div className={`dragItem__content ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''}`}>
          <span
            {...props}
            {...listeners}
            style={{
              cursor: 'grab',
            }}
          >
            <MenuIcon />
          </span>
          <div className={`${data?.isArchived ? 'dragList__archived' : ''}`}>
            {data?.isArchived && (
              <div className='dragList__archivedAlert'>{`(${f({
                id: 'button.more.dropDownMenu.archived',
              })})`}</div>
            )}
            <div>{data?.name}</div>
          </div>
          <MoreButton
            name={data?.name}
            isActivate={!data?.isArchived}
            editHandler={() => onEdit(data)}
            archiveHandler={() => archive(data?._id, data?.name, 'category')}
            activateHandler={() => activate(data?._id)}
            delHandler={() => onDelete({ data, type: 'category', isArchived: data?.isArchived })}
            className={'caseDragList-more'}
          />
        </div>
      </StyledDragItem>
    );
  },
);

const SortableItem = ({ activeIndex, data, isSelected, ...props }) => {
  const { attributes, listeners, isDragging, setNodeRef, over } = useSortable({
    id: data.priority,
    animateLayoutChanges: () => true,
  });
  const position = over?.id === data.priority ? (over?.id <= activeIndex ? -1 : 1) : undefined;

  return (
    <Item
      ref={setNodeRef}
      listeners={listeners}
      className={`sortable ${isDragging ? 'dragging' : ''} ${position === 1 ? 'insertAfter' : ''} ${
        position === -1 && 'insertBefore'
      } ${isSelected ? 'selected' : ''}`}
      data={data}
      isDragging={isDragging}
      isSelected={isSelected}
      {...attributes}
      {...props}
    />
  );
};
export default SortableItem;
