import { createSlice } from '@reduxjs/toolkit';

const caseTypeSlice = createSlice({
  name: 'settingPage/caseType',
  initialState: {
    caseType: [],
    editing: {
      _id: '',
      name: '',
      isCommonly: false,
    },
    deleting: {
      _id: '',
      name: '',
    },
    loading: false,
  },
  reducers: {
    getCaseTypeStart: state => {
      state.loading = true;
    },
    getCaseType: (state, action) => {
      state.caseType = action.payload;
      state.loading = false;
    },
    editingCaseType: (state, action) => {
      state.editing = { ...state.editing, ...action.payload };
    },
    resetForm: state => {
      state.editing._id = '';
      state.editing.name = '';
      state.editing.isCommonly = false;
    },
    selectCaseTypeItem: (state, action) => {
      const current = action.payload;
      state.editing.name = current.name;
      state.editing._id = current._id;
      state.editing.isCommonly = current.isCommonly;
    },
    getDeleteTarget: (state, action) => {
      state.deleting._id = action.payload._id;
      state.deleting.name = action.payload.name;
    },
    resetDeleteForm: state => {
      state.deleting._id = '';
      state.deleting.name = '';
    },
    updateOriginalCaseType: (state, action) => {
      state.caseType = state.caseType.map(type => {
        if (type._id === action.payload._id) {
          return action.payload;
        }
        return type;
      });
    },
    filterDeleteData: (state, action) => {
      state.caseType = state.caseType.filter(type => type._id !== action.payload._id);
    },
  },
});

export const {
  getCaseTypeStart,
  getCaseType,
  selectCaseTypeItem,
  getDeleteTarget,
  updateOriginalCaseType,
  editingCaseType,
  resetForm,
  resetDeleteForm,
  filterDeleteData,
} = caseTypeSlice.actions;
export default caseTypeSlice.reducer;
