import { Button, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { ReactComponent as MoreIcon } from '../../images/icons-more-horizontal.svg';

//#region
export const StyledDropdown = createGlobalStyle`
  .moreButton__dropDown {
    height: 186px;
    .moreButton__name {
      color: var(--default-500-hint);
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 4px 15px 0;
    }
    .ant-dropdown-menu {
        text-align: center;
        .ant-dropdown-menu-item {
            margin: 8px;
            font-weight: 500;
            &:hover {
                background-color: var(--color-fill-1-primary-100);
                border-radius: 8px;
            }
            &.moreButton__delete {
            color: var(--default-500-danger);
            &:hover {
                background-color: var(--light-100-danger);
                border-radius: 8px;
            }
            }
        }
    }
}
`;
//#endregion

const MoreButton = ({ name, isActivate, editHandler, archiveHandler, activateHandler, delHandler, className }) => {
  const { formatMessage: f } = useIntl();
  const [detectWidth, setDetectWidth] = useState(108);
  const [detected, setDetected] = useState(false);
  const dropMenu = (
    <Menu style={{ width: detectWidth + 40 }}>
      <div key='moreButton__name' className='moreButton__name'>
        {name}
      </div>
      <Menu.Divider />
      <Menu.Item key='moreButton__edit' onClick={editHandler}>
        <span>{f({ id: 'button.more.dropDownMenu.edit' })}</span>
      </Menu.Item>
      <Menu.Item
        key={isActivate ? 'moreButton__active' : 'moreButton__inactive'}
        onClick={isActivate ? archiveHandler : activateHandler}
      >
        <span>
          {isActivate ? f({ id: 'button.more.dropDownMenu.archived' }) : f({ id: 'button.more.dropDownMenu.active' })}
        </span>
      </Menu.Item>
      <Menu.Item key='moreButton__delete' onClick={delHandler} className='moreButton__delete'>
        <span>{f({ id: 'button.more.dropDownMenu.del' })}</span>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    // el only detect once
    if (detected) {
      let elementWidth = [];
      const el = document.querySelectorAll('.moreButton__dropDown .ant-dropdown-menu-item span');
      el.forEach(el => elementWidth.push(el.offsetWidth));
      const max = Math.max(...elementWidth);
      setDetectWidth(prev => (max > prev ? max : prev));
    }
  }, [detected]);

  const getWidth = () => {
    if (!detected) setDetected(true);
  };

  return (
    <Dropdown overlay={dropMenu} trigger={['click']} overlayClassName={'moreButton__dropDown'} placement='bottomCenter'>
      <Button icon={<MoreIcon />} type='text' onClick={getWidth} className={className} />
    </Dropdown>
  );
};

export default MoreButton;

MoreButton.propTypes = {
  name: PropTypes.string,
  isActivate: PropTypes.bool,
  editHandler: PropTypes.func,
  archiveHandler: PropTypes.func,
  activateHandler: PropTypes.func,
  delHandler: PropTypes.func,
  className: PropTypes.string,
};

MoreButton.defaultProps = {
  name: '',
  className: '',
  isActivate: true,
  editHandler: () => {},
  archiveHandler: () => {},
  activateHandler: () => {},
  delHandler: () => {},
};
