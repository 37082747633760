import axios from 'axios';
import { getAccessToken, getWorkspaceId } from './token';
import { api_url } from '@/config';
import store from '../store';
import {
  getUsedList,
  setClientError,
  setDuplicateName,
  setHasChildrenModalVisible,
  setPasswordError,
  setServerError,
  setUsedCaseModalVisible,
} from '@/container/HomePage/slice/errorCenterSlice';

const instance = axios.create({
  baseURL: api_url,
  headers: {
    'content-type': 'application/json',
  },
});

instance.interceptors.request.use(
  config => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers.workspaceId = getWorkspaceId();
    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const status = error.response.status;
      if (status.toString()[0] === '5') {
        return store.dispatch(setServerError(true));
      }
      if (error.response.data.errorData) {
        const errorCode = String(error.response.data.errorData.errorCode);
        switch (errorCode) {
          case 'MD11000':
          case 'LY0003':
          case 'PG0003':
          case 'PS0003':
          case 'PS0009':
          case 'IB0004':
          case 'IB0013': {
            store.dispatch(setDuplicateName(true));
            break;
          }
          case 'LY0005':
          case 'IB0017':
          case 'IB0008':
          case 'PS0005':
          case 'PS0011': {
            store.dispatch(getUsedList(error.response.data.errorData.data));
            store.dispatch(setUsedCaseModalVisible(true));
            break;
          }
          case 'IB0018': {
            store.dispatch(setHasChildrenModalVisible(true));
            break;
          }
          case 'SSOAU0004':
          case 'SSOAU0007': {
            store.dispatch(setPasswordError(true));
            break;
          }
          case 'AU0007': {
            return (window.location.href = window.location.href + '/invalid');
          }
          case 'HS0001':
          case 'HS0002':
          case 'HS0003':
          case 'HS0004': {
            return Promise.reject(error);
          }
          default: {
            store.dispatch(setClientError(true));
            return Promise.reject(error);
          }
        }
      }
    }
    return Promise.reject(error);
  },
);

export const get = async (url, params) => {
  let addPage = hasPaginationUrl(url);
  if (addPage) {
    if (!params?.page) {
      let page = 1;
      params = { ...params, page };
    } else {
      params = { ...params, page: params.page + 1 };
    }
  }
  return instance
    .get(url, {
      params,
    })
    .then(({ data }) => data)
    .then(data => {
      if (data.length === 50 && addPage) {
        return get(url, params).then(result => data.concat(result));
      }
      return data;
    })
    .catch(error => console.error(error));
};

export const edit = async ({ url, method, params, data }) => {
  return instance({
    url,
    method,
    params,
    data,
  })
    .then(({ data }) => data)
    .catch(error => console.error(error));
};

const hasPaginationUrl = url => {
  switch (url) {
    case '/laboratory':
    case '/treatmentCategory':
    case '/paymentMethod':
    case '/labOrder':
    case '/instructionBookCategory':
    case '/instructionBookItem':
      return true;
    default:
      return false;
  }
};
