import LabSetting from '../../../models/labSetting';
import { getLaboratory, getLaboratoryStart, updateOriginalLabs, filterDeleteData } from '../slice/laboratorySlice';

export const getLab = () => async dispatch => {
  dispatch(getLaboratoryStart());
  return await LabSetting.getLabSetting().then(res => dispatch(getLaboratory(res)));
};

export const createLab = async (data, successCallback) => {
  return await LabSetting.createLab(data).then(res => {
    if (!!res) {
      return successCallback();
    }
  });
};

export const editLab = (data, successCallback) => async dispatch => {
  return await LabSetting.editLab(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(updateOriginalLabs(res));
    }
  });
};

export const deleteLab = (data, successCallback) => async dispatch => {
  return await LabSetting.editLab(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(filterDeleteData(data));
    }
  });
};
