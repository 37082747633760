import React, { useState } from 'react';
import LabSettingHeader from './component/LabSettingHeader';
import { Button } from '../../component/Button';
import { ReactComponent as PlusIcon } from '../../images/icons-plus.svg';
import CasePayContent from './component/CasePayContent';
import { CaseItemsContainer } from './CaseType';

const CasePay = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [sort, setSort] = useState('name');
  const handleVisible = () => setDrawerVisible(true);
  const handleInvisible = () => setDrawerVisible(false);

  return (
    <CaseItemsContainer>
      <LabSettingHeader
        title='casePay.title'
        sortHandler={setSort}
        sortValue={sort}
        content={
          <div className='settingTitle-content'>
            <Button
              type='primary'
              width={'85px'}
              icon={<PlusIcon />}
              onClick={handleVisible}
              buttonText={'button.add'}
            />
          </div>
        }
      />
      <CasePayContent
        drawerVisible={drawerVisible}
        handleDrawerClose={handleInvisible}
        handleVisible={handleVisible}
        sort={sort}
      />
    </CaseItemsContainer>
  );
};

export default CasePay;
