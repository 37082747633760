import _ from 'lodash';
import { lazy } from 'react';
import MainLayout from '../component/MainLayout';
import LabSetting from '../container/SettingPage/LabSetting';
import CaseLi from '../container/SettingPage/CaseLi';
import CaseType from '../container/SettingPage/CaseType';
import CasePay from '../container/SettingPage/CasePay';

const HomePage = lazy(() => import('../container/HomePage'));
const CasePage = lazy(() => import('../container/CasePage'));
const SettingPage = lazy(() => import('../container/SettingPage'));
const LoginPage = lazy(() => import('../container/LoginPage'));
const NotFoundPage = lazy(() => import('../container/NotFoundPage'));
const SharedPage = lazy(() => import('../container/SharedPage'));
const SharedNotFound = lazy(() => import('../container/SharedNotFoundPage'));
const LandingPage = lazy(() => import('../container/LandingPage'));

export const routes = (currentUser) => [
  {
    path: '/',
    name: 'main',
    key: 'main',
    children: [
      { index: true, element: <LandingPage currentUser={currentUser} /> },
      {
        path: 'login',
        name: '登入',
        key: 'login',
        children: [
          { index: true, element: <LoginPage currentUser={currentUser} /> },
        ]
      },
      {
        path: ':clinicId',
        name: '技工管理',
        key: 'home',
        element: <MainLayout currentUser={currentUser} />,
        children: [
          { index: true, element: <HomePage /> },
          {
            path: 'case',
            key: 'case',
            children: [
              {
                path: ':patientId',
                children: [
                  { index: true, element: <CasePage /> },
                  {
                    path: ':id',
                    element: <CasePage />,
                  }
                ]
              }
            ]
          },
          {
            path: 'setting',
            element: <SettingPage />,
            children: [
              { path: 'lab', element: <LabSetting /> },
              { path: 'case-li', element: <CaseLi /> },
              { path: 'case-type', element: <CaseType /> },
              { path: 'case-pay', element: <CasePay /> },
            ],
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
    ]
  },
  {
    path: '/share/:caseId',
    name: '分享',
    key: 'share',
    element: <SharedPage />,
  },
  {
    path: '/invalid',
    element: <SharedNotFound />,
  },
  {
    path: '*',
    element: !_.isEmpty(currentUser) ? <NotFoundPage /> : <SharedNotFound />,
  },
];
