import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Spin } from 'antd';
import { useForm } from 'react-hook-form';
import DeleteForm from './DeleteForm';
import CustomizeModal from '../../../component/Modal';
import { Button } from '../../../component/Button';
import { EllipsisText } from '../../../component/Display';
import redirectToCase from '../utils/redirectToCase';

//#region
const UsedLi = styled.div`
  font-size: 15px;
  color: var(--default-500-primary);
  cursor: pointer;
`;
//#endregion

const DeleteModal = ({
  deleteTitle = '',
  deleteName = '',
  deleteModalVisible = false,
  handleDelete = () => {},
  handleCancel = () => {},
  failModalVisible = false,
  handleModalFailVisible = () => {},
  deleteFailedTitle = '',
  deleteFailedDescription = '',
  usedList = [],
  hasItemModalVisible = false,
  handleHasCaseItemModalVisible = () => {},
  serverError = false,
  clinicId,
}) => {
  const { formatMessage: f } = useIntl();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  return (
    <React.Fragment>
      <CustomizeModal
        type={'danger'}
        title={
          <EllipsisText>
            <EllipsisText.Normal>{f({ id: deleteTitle })}</EllipsisText.Normal>
            <EllipsisText.Ellipsis>{deleteName}</EllipsisText.Ellipsis>
            <EllipsisText.Normal>?</EllipsisText.Normal>
          </EllipsisText>
        }
        visible={deleteModalVisible}
        content={<DeleteForm control={control} error={errors} alert={serverError} />}
        showCloseIcon={false}
        handleCancel={() => {
          handleCancel();
          reset({ reason: '', person: '' });
        }}
        handleOk={handleSubmit(data => {
          handleDelete(data);
          reset({ reason: '', person: '' });
        })}
        footer={
          <div>
            <Button
              buttonText='button.delete'
              type={'danger'}
              size={'large'}
              width={'98px'}
              onClick={handleSubmit(data => {
                handleDelete(data);
                reset({ reason: '', person: '' });
              })}
            />
            <Button
              buttonText='button.cancel'
              type={'ghost-basic'}
              size={'large'}
              width={'98px'}
              onClick={() => {
                handleCancel();
                reset({ reason: '', person: '' });
              }}
            />
          </div>
        }
      />
      <CustomizeModal
        type={'scroll-content'}
        title={`${f({ id: deleteFailedTitle })} ${deleteName}`}
        visible={failModalVisible}
        showCloseIcon={false}
        handleCancel={handleModalFailVisible}
        handleOk={handleModalFailVisible}
        content={
          <div>
            <div>
              {deleteName} {f({ id: deleteFailedDescription })} :
            </div>
            {usedList.length === 0 ? (
              <Spin />
            ) : (
              <>
                {usedList.map(li => (
                  <UsedLi key={li.name} onClick={() => redirectToCase(li.name, clinicId)}>
                    {li.name}
                  </UsedLi>
                ))}
              </>
            )}
          </div>
        }
        footer={
          <div>
            <Button
              buttonText='button.ok'
              type={'primary'}
              size={'large'}
              isBlock={true}
              onClick={handleModalFailVisible}
            />
          </div>
        }
      />
      <CustomizeModal
        className='wide'
        title={`${f({ id: deleteFailedTitle })} ${deleteName}`}
        visible={hasItemModalVisible}
        showCloseIcon={false}
        content={<div>{f({ id: 'modal.delete.hasCaseItem.description' })}</div>}
        handleCancel={handleHasCaseItemModalVisible}
        handleOk={handleHasCaseItemModalVisible}
        footer={
          <div>
            <Button
              buttonText='button.ok'
              type={'primary'}
              size={'large'}
              isBlock={true}
              onClick={handleHasCaseItemModalVisible}
            />
          </div>
        }
      />
    </React.Fragment>
  );
};

export default DeleteModal;
