import styled from 'styled-components';
import { useIntl } from 'react-intl';

//#region
const Message = styled.span`
  font-size: 12px;
  color: var(--default-500-danger);
  margin: ${props => (props.margin ? props.margin : '2px 0 4px')};
`;
//#endregion

const AlertMessage = ({ className, message = 'form.alert', margin, ...props }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Message className={className} margin={margin} {...props}>
      {f({ id: message })}
    </Message>
  );
};

export default AlertMessage;
