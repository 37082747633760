import React from 'react';
import { Button as BtnAntd } from 'antd';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';

//#region
const disable = css`
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background-color: var(--basic-fill-03-300);
    color: rgb(126 130 153 / 50%);
    border: none;
  }
`;

const StyledButton = styled(BtnAntd)`
  color: var(--default-500-light);
  border: 0;
  font-weight: bold;
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => (props.doublepadding ? '0 56px' : '0 28px')};
  box-shadow: none;
  &::before {
    background: inherit;
  }
  &.ant-btn-primary {
    &:active {
      background-color: var(--color-fill-1-primary-700);
    }
    &:focus {
      background-color: var(--color-fill-1-primary-600);
    }
    ${disable}
  }
  &.ant-btn-danger {
    &:active {
      background-color: var(--color-fill-5-danger-700);
    }
    &:focus {
      background-color: var(--color-fill-5-danger-600);
    }
    ${disable}
  }
  &.ant-btn-warning {
    background-color: var(--color-fill-4-warning-500);
    &:hover {
      color: var(--default-500-light);
      background-color: var(--color-fill-4-warning-400);
    }
    &:active {
      background-color: var(--color-fill-4-warning-700);
    }
    &:focus {
      background-color: var(--color-fill-4-warning-600);
    }
    ${disable}
  }
  &.ant-btn-loading.ant-btn-danger {
    background-color: var(--color-fill-5-danger-700);
  }
  &.ant-btn-loading.ant-btn-primary {
    background-color: var(--color-fill-1-primary-700);
  }
  &.btn-type-secondary {
    color: var(--basic-fill-08-800);
    background-color: ${props => (props.isLoading ? 'var(--default-500-hint)' : 'var(--default-500-secondary)')};
    &:hover {
      color: var(--basic-fill-08-800);
      background-color: var(--basic-fill-03-300);
    }
    &:focus {
      color: var(--basic-fill-08-800);
      background-color: var(--default-500-hint);
    }
    &:active {
      color: var(--default-500-light);
      background-color: var(--basic-fill-07-700);
    }
    ${disable};
  }
  &.btn-type-black {
    border: 0;
    background-color: ${props => (props.isLoading ? 'var(--basic-fill-09-900)' : 'var(--basic-fill-08-800)')};
    &:hover {
      border: 0;
      color: var(--default-500-light);
      background-color: var(--basic-fill-07-700);
    }
    &:focus {
      border: 0;
      color: var(--default-500-light);
      background-color: var(--basic-fill-09-900);
    }
    &:active {
      border: 0;
      color: var(--default-500-light);
      background-color: var(--basic-fill-10-1000);
    }
    ${disable};
  }
  &.btn-type-outline-primary {
    background-color: ${props =>
      props.isLoading ? 'var(--transparent-fill-1-primary-48)' : 'var(--transparent-fill-1-primary-08)'};
    color: var(--default-500-primary);
    border: solid 1px var(--default-500-primary);
    &:hover {
      color: var(--default-500-primary);
      border: solid 1px var(--default-500-primary);
      background-color: var(--transparent-fill-1-primary-24);
    }
    &:focus {
      color: var(--default-500-primary);
      border: solid 1px var(--default-500-primary);
      background-color: var(--transparent-fill-1-primary-48);
    }
    &:active {
      color: var(--default-500-primary);
      border: solid 1px var(--default-500-primary);
      background-color: var(--transparent-fill-1-primary-40);
    }
    ${disable};
  }
  &.btn-type-outline-basic {
    background-color: ${props =>
      props.isLoading ? 'var(--transparent-fill-2-basic-48)' : 'var(--transparent-fill-2-basic-08)'};
    color: var(--default-500-hint);
    border: solid 1px var(--default-500-hint);
    &:hover {
      border: solid 1px var(--default-500-hint);
      color: var(--default-500-hint);
      background-color: var(--transparent-fill-2-basic-24);
    }
    &:focus {
      border: solid 1px var(--default-500-hint);
      color: var(--default-500-hint);
      background-color: var(--transparent-fill-2-basic-48);
    }
    &:active {
      border: solid 1px var(--default-500-hint);
      color: var(--default-500-hint);
      background-color: var(--basic-fill-06-600);
    }
    ${disable};
  }
  &.btn-type-outline-danger {
    background-color: ${props => (props.isLoading ? 'var(--transparent-fill-6-danger-48)' : 'var(--light-100-danger)')};
    color: var(--default-500-danger);
    border: solid 1px var(--default-500-danger);
    &:hover {
      color: var(--default-500-danger);
      border: solid 1px var(--default-500-danger);
      background-color: var(--transparent-fill-6-danger-24);
    }
    &:focus {
      color: var(--default-500-danger);
      border: solid 1px var(--default-500-danger);
      background-color: var(--transparent-fill-6-danger-48);
    }
    &:active {
      color: var(--default-500-danger);
      border: solid 1px var(--default-500-danger);
      background-color: var(--color-fill-05-danger-500);
    }
    ${disable};
  }
  &.btn-type-outline-warning {
    background-color: ${props =>
      props.isLoading ? 'var(--transparent-fill-5-warning-48)' : 'var(--transparent-fill-1-5-warning-08)'};
    color: var(--color-fill-4-warning-500);
    border: solid 1px var(--color-fill-4-warning-500);
    &:hover {
      color: var(--color-fill-4-warning-500);
      border: solid 1px var(--default-500-primary);
      background-color: var(--transparent-fill-5-warning-24);
    }
    &:focus {
      color: var(--default-500-primary);
      border: solid 1px var(--default-500-primary);
      background-color: var(--transparent-fill-5-warning-48);
    }
    &:active {
      color: var(--default-500-primary);
      border: solid 1px var(--default-500-primary);
      background-color: var(--transparent-fill-5-warning-40);
    }
    ${disable};
  }
  &.btn-type-ghost-primary {
    background-color: transparent;
    color: var(--default-500-primary);
    border: 0;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: var(--default-500-primary);
      border: 0;
    }
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background-color: var(--transparent-0);
      color: var(--basic-fill-06-600);
    }
  }
  &.btn-type-ghost-basic {
    background-color: transparent;
    color: var(--basic-fill-06-600);
    border: 0;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: var(--basic-fill-06-600);
      border: 0;
    }
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background-color: var(--transparent-0);
      color: var(--basic-fill-06-600);
    }
  }
  &.btn-type-ghost-danger {
    background-color: transparent;
    color: var(--default-500-danger);
    border: 0;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: var(--default-500-danger);
      border: 0;
    }
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background-color: var(--transparent-0);
      color: var(--basic-fill-06-600);
    }
  }
  &.btn-type-ghost-warning {
    background-color: transparent;
    color: var(--color-fill-4-warning-500);
    border: 0;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: var(--color-fill-4-warning-500);
      border: 0;
    }
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background-color: var(--transparent-0);
      color: var(--basic-fill-06-600);
    }
  }
`;
//#endregion

const Button = ({
  buttonText = 'button.search',
  size = 'middle',
  type = 'primary',
  isLoading = false,
  isBlock = false,
  onClick = () => {},
  isDisable = false,
  width,
  height,
  doublePadding = false,
  icon,
  ...props
}) => {
  const { formatMessage: f } = useIntl();
  return (
    <StyledButton
      shape='round'
      type={type}
      size={size}
      block={isBlock}
      disabled={isDisable}
      onClick={onClick}
      loading={isLoading}
      className={`btn-type-${type}`}
      width={width}
      height={height}
      icon={icon}
      doublepadding={doublePadding ? 'doublepadding' : undefined}
      {...props}
    >
      {!isLoading && f({ id: buttonText }).toLocaleUpperCase()}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  buttonText: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  isBlock: PropTypes.bool,
  onClick: PropTypes.func,
  isDisable: PropTypes.bool,
  width: PropTypes.string,
  icon: PropTypes.element,
  doublePadding: PropTypes.bool,
};

Button.defaultProps = {
  buttonText: 'button.search',
  size: 'middle',
  type: 'primary',
  isLoading: false,
  isBlock: false,
  isDisable: false,
  width: '',
  onClick: () => {},
  icon: undefined,
  doublePadding: false,
};
