import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import LabSettingSelect from './LabSettingSelect';

//#region
const SettingHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 31px;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--default-500-light);
  border-radius: 8px;
  & > div {
    display: flex;
  }
  /* temp */
  @media (max-width: 768px) {
    .l-caseLi-header {
      flex-direction: column;
      &__content button:first-child {
        margin: 0;
      }
    }
  }
  .settingTitle-content {
    display: flex;
  }
  .ant-btn {
    margin-left: 1.24em;
    align-items: center;
    padding: 0 12px 0 5px;
    display: flex;
    justify-content: space-around;
    &:first-child {
      margin-left: 1.75em;
    }
  }
  /* temp style */
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
//#endregion

const SettingHeader = ({ title, sortHandler, sortValue, content, className }) => {
  const { formatMessage: f } = useIntl();
  return (
    <SettingHeaderContainer>
      <div className={className}>
        <h5>{f({ id: title })}</h5>
        {content}
      </div>
      <LabSettingSelect selectHandler={value => sortHandler(value)} value={sortValue} />
    </SettingHeaderContainer>
  );
};

export default SettingHeader;

SettingHeader.propsType = {
  title: PropTypes.string,
  sortHandler: PropTypes.func,
  sortValue: PropTypes.string,
  content: PropTypes.element,
};

SettingHeader.defaultProps = {
  title: '',
  content: undefined,
  sortValue: 'name',
  sortHandler: () => {},
};
