const findProgress = (progressItemPackage = [], progressItemNameOrId = '') => {
  const progress = progressItemPackage.find(
    item => item.name === progressItemNameOrId || item._id === progressItemNameOrId,
  );
  const indexById = progressItemPackage.map(p => p._id).indexOf(progressItemNameOrId);
  const indexByName = progressItemPackage.map(p => p.name).indexOf(progressItemNameOrId);
  const index = indexById === indexByName ? indexById : indexById === -1 ? indexByName : indexById;
  return { progress, index };
};

export default findProgress;
