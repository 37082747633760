import _ from 'lodash';
import appendQuery from 'append-query';
import { cas_login_url } from '@/config';

export default class SSORedirectUtil {
  static getHref() {
    return window.location.href;
  }

  static redirectHref(href) {
    if (_.isEmpty(href)) {
      window.location.href = '/';
    } else {
      window.location.href = href;
    }
  }

  static redirectCasLoginPath(useRecentUrl = true) {
    if (useRecentUrl) {
      return this.redirectHref(appendQuery(cas_login_url, { redirectUrl: this.getHref() }));
    }
    return this.redirectHref();
  }
}
