import React, { forwardRef } from 'react';
import { Input as AntInput } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//#region
const StyledInput = styled(AntInput)`
  margin-top: 3px;
  border: ${({ alert }) => alert && '1px solid var(--default-500-danger)'};
  ::placeholder {
    ${props => (props.size !== 'large' ? `font-size: 13px;` : `font-size: 15px;`)}
  }
  &[disabled] {
    ::placeholder {
      color: var(--disabled-color);
    }
  }
`;
//#endregion

const Input = forwardRef(({ value, onChange, placeholder, alert, size, disabled, className, ...props }, ref) => {
  return (
    <StyledInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      alert={alert ? 'alert' : undefined}
      disabled={disabled}
      ref={ref}
      className={className}
      {...props}
    />
  );
});

export default Input;

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  alert: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Input.defaultTypes = {
  value: '',
  onChange: () => {},
  placeholder: '請輸入',
  alert: false,
  size: 'large',
  disabled: false,
  className: undefined,
};
