import { edit, get } from '../utils/api';

const requestUrl = `/progressItem`;

export default class Progress {
  static getProgress = async () => {
    const query = {
      sort: JSON.stringify([{ property: 'priority', direction: 'ASC' }]),
    };
    const result = await get(requestUrl, query);
    return result;
  };

  static getProgressById = async params => {
    const result = await get(`${requestUrl}/${params}`);
    return result;
  };

  static createProgress = async data => {
    const result = await edit({ url: requestUrl, method: 'post', data });
    return result;
  };

  static editProgress = async data => {
    const result = await edit({ url: requestUrl, method: 'put', data });
    return result;
  };
}
