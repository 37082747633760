import { Modal } from 'antd';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../images/icons-close.svg';
import trash from '../../images/icon-his-icons-trash.svg';
import alertCircle from '../../images/0-icon-alert-circle-outline-thick.svg';

//#region
const StyledModal = styled(Modal)`
  position: relative;
  width: auto;
  @media screen and (min-width: 480px) {
    width: 440px !important;
  }
  @media screen and (min-width: 768px) {
    width: 600px !important;
  }
  &.wide {
    .ant-modal-header {
      padding-top: 28px;
    }
    .ant-modal-body {
      padding: 10px 24px 30px;
    }
    .ant-modal-footer {
      padding: 16px 24px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding: 24px;
    .ant-modal-title {
      font-size: 22px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    ${props =>
      props.type === 'danger' &&
      `
        .ant-modal-title::before {
        content: '';
        background-image: url(${trash});
        width: 28px;
        height: 28px;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 12px;
    }`}
    ${props =>
      props.type === 'warning' &&
      `
        .ant-modal-title::before {
        content: '';
        background-image: url(${alertCircle});
        width: 28px;
        height: 28px;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 12px;
    }`}
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-footer {
    border-top: none;
    background-color: var(--basic-fill-02-200);
  }
  .ant-modal-close {
    top: 14px;
  }
  ${props =>
    props.type === 'scroll-content' &&
    `
    .ant-modal-header {
        box-shadow: 0px 4px 9px 0px rgb(0 0 0 / 10%);
        z-index: 2;
    }
    .ant-modal-footer {
      padding: 16px 24px;
    }
        .ant-modal-body {
        min-height: 120px;
        max-height: 310px;
        overflow-y: scroll;
        margin-top: 5px;
        padding-top: 10px;
    }
    `};
  ${props =>
    props.type === 'danger' &&
    `
        &::before {
        content: '';
        background-color: var(--color-fill-5-danger-500);
        width: 100%;
        height: 30px;
        position: absolute;
        top: -12px;
        border-radius: 8px;
    }`}
  ${props =>
    props.type === 'warning' &&
    `
        &::before {
        content: '';
        background-color: var(--default-500-warning);
        width: 100%;
        height: 30px;
        position: absolute;
        top: -12px;
        border-radius: 8px;
    }`}
`;
//#endregion

const CustomizeModal = ({
  title,
  type,
  visible,
  content,
  showCloseIcon,
  footer,
  handleCancel,
  className,
  handleOk,
}) => {
  return (
    <StyledModal
      className={className}
      type={type}
      title={title}
      visible={visible}
      footer={type === 'simple' ? null : footer}
      onCancel={handleCancel}
      onOk={handleOk}
      closable={showCloseIcon}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      destroyOnClose={true}
      centered
    >
      {content}
    </StyledModal>
  );
};

export default CustomizeModal;
