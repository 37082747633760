import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { Tooltip } from 'antd';
import { TableFilter, Button, MoreButton, StarMark } from '../../../component/Button';
import { Input } from '../../../component/Input';
import { labSettingFilters } from '../constant';
import Empty from '../../../component/Empty';

//#region
const CaseItemListContainer = styled.div`
  &.caseListContent {
    padding: 0 32px 32px;
    border-radius: 8px;

    .caseContent__stickyArea {
      position: sticky;
      top: 74px;
      background-color: var(--default-500-light);
      z-index: 3;
    }
    .caseContent__filter {
      padding: 12px 24px 12px 24px;
      border: 1px solid var(--light-100-dark);
      border-bottom: 1px solid var(--border-bottom);
      border-radius: 8px 8px 0 0;
      & > span {
        font-weight: bold;
      }
    }
    .caseContent__header {
      padding: 0.5em 1.25em 1em;
      border-left: 1px solid var(--light-100-dark);
      border-right: 1px solid var(--light-100-dark);
    }
    .caseContent__caseList {
      border: 1px solid var(--light-100-dark);
      border-top: none;
      border-radius: 0 0 8px 8px;
    }
  }
  .caseContent__search {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .caseContent__caseList {
    min-height: calc(100vh - 350px);
    ul {
      list-style-type: none;
      padding: 0;
      li.caseContent__caseItem {
        border-bottom: 1px solid var(--border-bottom);
        display: flex;
        align-items: center;
        padding: 10px 1.5em;
        &:hover {
          background-color: var(--basic-fill-02-200);
        }
        &:focus-within {
          background: var(--color-fill-1-primary-100);
        }
        .caseList__archived {
          color: var(--default-500-hint);
          font-style: italic;
          line-height: 15px;
        }
        .caseList__archivedAlert {
          font-size: 12px;
          z-index: 2;
          position: relative;
        }
        .caseContent__caseItem_more {
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.1s ease;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          margin-left: 1em;
          &:focus-within {
            visibility: visible;
            opacity: 1;
          }
        }
        &:hover {
          .caseContent__caseItem_more {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
`;
//#endregion

const CaseItemList = ({
  dataSource,
  title,
  emptyTitle,
  handleEdit,
  handleArchived,
  handleActivated,
  handleCommonly,
  handleDelete,
  filterValue,
  handleInputFilter,
  filters,
  handleFilter,
  className = 'caseListContent',
}) => {
  const { formatMessage: f } = useIntl();
  const [tempValue, setTempValue] = useState('');

  useEffect(() => {
    setTempValue(filterValue);
  }, [filterValue]);

  return (
    <CaseItemListContainer className={className}>
      <div className='caseContent__stickyArea'>
        <div className='caseContent__filter'>
          <span>{f({ id: title })}</span>
          <TableFilter options={labSettingFilters(f)} value={filters} onChange={handleFilter} />
        </div>
        <div className='caseContent__header'>
          <div className='caseContent__search'>
            <Input
              value={tempValue}
              onChange={e => setTempValue(e.target.value)}
              placeholder={f({ id: 'input.placeholder' })}
              size={'large'}
            />
            <Button
              buttonText='button.reset'
              width={'114px'}
              type='ghost-basic'
              onClick={() => {
                setTempValue('');
                handleInputFilter('');
              }}
            />
            <Button
              buttonText='button.search'
              width={'114px'}
              type='secondary'
              onClick={() => handleInputFilter(tempValue)}
            />
          </div>
        </div>
      </div>
      <div className='caseContent__caseList'>
        {dataSource.length === 0 && <Empty description={emptyTitle} />}
        {dataSource.length !== 0 && (
          <ul>
            {dataSource.map(data => (
              <li className={`caseContent__caseItem`} key={data._id}>
                <Tooltip title={data.name} className={`${data.isArchived ? 'caseList__archived' : ''}`}>
                  {data.isArchived && (
                    <div className='caseList__archivedAlert'>{`(${f({
                      id: 'button.more.dropDownMenu.archived',
                    })})`}</div>
                  )}
                  <Highlighter
                    searchWords={[filterValue]}
                    highlightClassName={`highlight`}
                    autoEscape={true}
                    textToHighlight={data.name}
                  />
                </Tooltip>
                <span className='caseContent__caseItem_more' tabIndex='0'>
                  {!data.isArchived && (
                    <StarMark isMark={data.isCommonly} clickHandler={() => handleCommonly(data._id, data.isCommonly)} />
                  )}
                  <MoreButton
                    name={data.name}
                    isActivate={!data.isArchived}
                    editHandler={() => handleEdit(data)}
                    archiveHandler={() => handleArchived(data._id, data.name)}
                    activateHandler={() => handleActivated(data._id)}
                    delHandler={() => handleDelete(data)}
                  />
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </CaseItemListContainer>
  );
};

export default CaseItemList;
