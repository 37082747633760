import _ from 'lodash';
import { removeAuthTokens, getAccessToken, saveAccessToken } from '@/utils/token';
import { socket } from '@/socket';
import { authenticated, updateCurrentUser, appInitFetching } from '@/container/HomePage/slice/authSlice';
import { instance } from '@/utils/request.utility';
import { appendStr } from '@/utils/redirect';

const name = 'app_init';

export const appInit = ({ search, st, navigate }) => async dispatch => {
  await dispatch(appInitFetching({ [name]: true }));
  const accessToken = await getAccessToken();

  if (_.isEmpty(accessToken)) {
    if (_.isEmpty(st)) {
      dispatch(authenticated(true));
      dispatch(updateCurrentUser({}));
    } else {
      try {
        const { data } = await instance({
          url: `/auth/access-token/${st}`,
          method: 'GET',
        });
        await saveAccessToken(data);
        const params = new URLSearchParams(search);
        const queriedStr = Object.fromEntries([...params]);
        delete queriedStr.st;
        const redirectStr = _.isEmpty(queriedStr) ? '' : appendStr('', queriedStr);
        navigate(`${window.location.pathname}${redirectStr}`, { replace: true });
      } catch (err) {
        console.log('Verify ST Failed: ', err);
        await removeAuthTokens();
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
        navigate(window.location.pathname, { replace: true });
      }
    }
  } else {
    if (!_.isEmpty(st)) {
      try {
        const { data } = await instance({
          url: `/auth/access-token/${st}`,
          method: 'GET',
        });
        await saveAccessToken(data);
        const params = new URLSearchParams(search);
        const queriedStr = Object.fromEntries([...params]);
        delete queriedStr.st;
        const redirectStr = _.isEmpty(queriedStr) ? '' : appendStr('', queriedStr);
        navigate(`${window.location.pathname}${redirectStr}`, { replace: true });
      } catch (err) {
        console.log('Verify ST Failed: ', err);
        await removeAuthTokens();
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
        navigate(window.location.pathname, { replace: true });
      }
    } else {
      try {
        const { data } = await instance({
          url: `/users/profile`,
          method: 'GET',
        });
        if (data._id) {
          socket.emit('joinRoom', { roomId: data._id });
          // await SocketUtil.connectSelfServer()
          dispatch(authenticated(true));
          dispatch(updateCurrentUser(data));
        } else {
          await removeAuthTokens();
          dispatch(authenticated(true));
          dispatch(updateCurrentUser({}));
        }
      } catch (error) {
        console.error('Verify Failed: ', error);
        await removeAuthTokens();
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
      }
    }
  }
  dispatch(appInitFetching({ [name]: false }));
};
