import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import toCaseProgress from '../utils/toCaseProgress';

const initCase = {
  patientData: {},
  doctorData: {},
  laboratoryId: '',
  laboratoryName: '',
  laboratoryDescription: '',
  laboratoryContact: '',
  laboratoryEmail: '',
  laboratoryPhone1: '',
  laboratoryPhone2: '',
  laboratoryAddress: '',
  manufactureProject: '',
  estimatedReturnDate: null,
  treatmentCategoryId: '',
  treatmentCategoryName: '',
  remarks: '',
  patientReturnDate: '',
  progressPackage: [],
  instructionBookPackage: [],
  otherDesc: '',
  shipmentNum: '',
  labNum: '',
  labPrice: null,
  paymentDate: '',
  paymentMethod: '',
  paymentMethodName: '',
  receipt: '',
  paymentDescription: '',
  isArchived: false,
  archivedOperator: '',
  images: [],
};
const caseSlice = createSlice({
  name: 'casePage/case',
  initialState: {
    uploadList: [],
    loading: false,
    progressLoading: false,
    case: initCase,
    deleting: {
      deletedReason: '',
      deletedOperator: '',
      password: '',
    },
    caseInfo: {
      createdDate: '',
      lastUpdatedDate: '',
      name: '',
      _id: '',
    },
    history: [],
    edited: false,
  },
  reducers: {
    updateUploadList: (state, action) => {
      state.edited = true;
      const index = action.payload.index;
      const toUpdateDisplayStatus = {
        name: state.uploadList[index].name,
        uid: state.uploadList[index].uid,
        status: 'done',
        url: action.payload.success.url,
        uploadedDate: action.payload.success.uploadedDate,
        fileType: action.payload.success.type,
        tempUrl: action.payload.success.tempUrl,
        file: action.payload.success.file
      };
      state.uploadList = [
        ...state.uploadList.slice(0, index),
        toUpdateDisplayStatus,
        ...state.uploadList.slice(index + 1),
      ];
      state.case.images = [...state.case.images, { ...action.payload.success, name: state.uploadList[index].name }];
    },
    preUpdateUploadListDisplay: (state, action) => {
      state.uploadList = [...state.uploadList, action.payload];
    },
    updateOnError: (state, action) => {
      state.uploadList = state.uploadList.filter(img => img.uid !== action.payload);
    },
    removeImage: (state, action) => {
      const { url, hasTemp = false } = action.payload;

      state.edited = true;
      state.uploadList = state.uploadList.filter(list => {
        if (hasTemp) {
          return list.tempUrl !== url;
        }
        return list.url !== url;
      });
      state.case.images = state.case.images.filter(list => {
        if (hasTemp) {
          return list.tempUrl !== url;
        }
        return list.url !== url;
      });
    },
    getCaseStart: state => {
      state.loading = true;
    },
    getCaseSuccess: (state, action) => {
      state.loading = false;
      state.caseInfo.createdDate = action.payload.createdDate;
      state.caseInfo.lastUpdatedDate = action.payload.lastUpdatedDate;
      state.case.doctorData = action.payload.doctorData;
      state.case.estimatedReturnDate = action.payload.estimatedReturnDate;
      state.case.images = action.payload.images;
      state.uploadList = action.payload.images.map(img => ({
        uid: img.url,
        name: img.name,
        status: 'done',
        url: img.url,
        uploadedDate: img.uploadedDate,
        fileType: img.type,
      }));
      state.case.instructionBookPackage = action.payload.instructionBookPackage;
      state.case.isArchived = action.payload.isArchived;
      state.case.archivedOperator = action.payload.archivedOperator;
      state.case.isDeleted = action.payload.isDeleted;
      state.case.labNum = action.payload.labNum;
      state.case.labPrice = action.payload.labPrice;
      state.case.laboratoryAddress = action.payload.laboratoryAddress;
      state.case.laboratoryContact = action.payload.laboratoryContact;
      state.case.laboratoryDescription = action.payload.laboratoryDescription;
      state.case.laboratoryEmail = action.payload.laboratoryEmail;
      state.case.laboratoryId = action.payload.laboratoryId;
      state.case.laboratoryName = action.payload.laboratoryName;
      state.case.laboratoryPhone1 = action.payload.laboratoryPhone1;
      state.case.laboratoryPhone2 = action.payload.laboratoryPhone2;
      state.case.manufactureProject = action.payload.manufactureProject;
      state.caseInfo.name = action.payload.name;
      state.case.otherDesc = action.payload.otherDesc;
      state.case.patientData = action.payload.patientData;
      state.case.patientReturnDate = action.payload.patientReturnDate;
      state.case.paymentDate = action.payload.paymentDate;
      state.case.paymentDescription = action.payload.paymentDescription;
      state.case.paymentMethod = action.payload.paymentMethod;
      state.case.paymentMethodName = action.payload.paymentMethodName;
      state.case.progressPackage = action.payload.progressPackage;
      state.case.receipt = action.payload.receipt;
      state.case.remarks = action.payload.remarks;
      state.case.shipmentNum = action.payload.shipmentNum;
      state.case.treatmentCategoryId = action.payload.treatmentCategoryId;
      state.case.treatmentCategoryName = action.payload.treatmentCategoryName;
      state.caseInfo._id = action.payload._id;
      state.history = action.payload.histories
        .slice()
        .sort((a, b) => moment(b.createdDate).unix() - moment(a.createdDate).unix());
      state.edited = false;
    },
    getProgressStart: state => {
      state.progressLoading = true;
    },
    getProgressSuccess: (state, action) => {
      state.progressLoading = false;
      state.case.progressPackage = toCaseProgress(action.payload);
    },
    updateCase: (state, action) => {
      state.edited = true;
      state.case = { ...state.case, ...action.payload };
    },
    updateAfterPost: (state, action) => {
      state.history.unshift(action.payload.history);
      state.caseInfo.lastUpdatedDate = action.payload.lastUpdatedDate;
      state.case.images = action.payload.updatedOrder.images;
      state.uploadList = action.payload.updatedOrder.images.map(img => ({
        uid: img.url,
        name: img.name,
        status: 'done',
        url: img.url,
        uploadedDate: img.uploadedDate,
        fileType: img.type,
      }));
    },
    updateLab: (state, action) => {
      state.edited = true;
      const lab = action.payload;
      state.case.laboratoryId = lab._id;
      state.case.laboratoryName = lab.name;
      state.case.laboratoryDescription = lab.description;
      state.case.laboratoryContact = lab.contact;
      state.case.laboratoryPhone1 = lab.phone1;
      state.case.laboratoryPhone2 = lab.phone2;
      state.case.laboratoryAddress = lab.address;
    },
    updateTreatment: (state, action) => {
      state.edited = true;
      const treatment = action.payload;
      state.case.treatmentCategoryId = treatment._id;
      state.case.treatmentCategoryName = treatment.name;
    },
    updateProgress: (state, action) => {
      state.edited = true;
      const { status, date, operator } = action.payload;
      const getKey = Object.keys(action.payload);
      state.case.progressPackage = state.case.progressPackage.map(progress => {
        if (progress._id === action.payload.id) {
          if (getKey.includes('status')) {
            progress.status = status;
          }
          if (getKey.includes('date')) {
            progress.completedDate = date;
          }
          if (getKey.includes('operator')) {
            progress.operator = operator;
          }
        }
        return progress;
      });
    },
    setIsArchived: (state, action) => {
      state.edited = true;
      state.case.isArchived = action.payload;
      if (!action.payload) {
        state.case.archivedOperator = '';
      }
    },
    initCaseLi: (state, action) => {
      state.case.instructionBookPackage = action.payload
        .filter(c => !c.isArchived)
        .map(ib => ({ categoryId: ib._id, categoryName: ib.name, priority: ib.priority, description: '', items: [] }))
        .slice()
        .sort((a, b) => a.priority - b.priority);
    },
    updateCaseLi: (state, action) => {
      state.edited = true;
      state.case.instructionBookPackage = state.case.instructionBookPackage.map(c => {
        if (c.categoryId === action.payload.categoryId) {
          c.items = action.payload.items.filter(item => item._id !== undefined || item.name !== undefined);
        }
        return c;
      });
    },
    updateCaseLiDescription: (state, action) => {
      state.edited = true;
      state.case.instructionBookPackage = state.case.instructionBookPackage.map(c => {
        if (c.categoryId === action.payload.categoryId) {
          c.description = action.payload.description;
        }
        return c;
      });
    },
    updatePayment: (state, action) => {
      state.edited = true;
      state.case.paymentMethod = action.payload.id;
      state.case.paymentMethodName = action.payload.name;
    },
    updateDeleteForm: (state, action) => {
      state.deleting = { ...state.deleting, ...action.payload };
    },
    resetCase: state => {
      state.case = initCase;
      state.edited = false;
      state.uploadList = [];
    },
    resetDeleteForm: state => {
      state.deleting.deletedReason = '';
      state.deleting.deletedOperator = '';
      state.deleting.password = '';
    },
    resetEdited: state => {
      state.edited = false;
    },
    setLastDoctor: (state, action) => {
      state.case.doctorData = action.payload;
    },
  },
});

export const {
  updateUploadList,
  updateCase,
  updateLab,
  updateTreatment,
  updateProgress,
  setIsDelete,
  setIsArchived,
  updateCaseLi,
  updateCaseLiDescription,
  updatePayment,
  preUpdateUploadListDisplay,
  removeImage,
  getCaseStart,
  getCaseSuccess,
  resetCase,
  resetDeleteForm,
  updateDeleteForm,
  getProgressStart,
  getProgressSuccess,
  resetEdited,
  updateAfterPost,
  updateOnError,
  setLastDoctor,
  initCaseLi,
} = caseSlice.actions;
export default caseSlice.reducer;
