import { createSlice } from '@reduxjs/toolkit';

const form = {
  name: '',
  description: '',
  contact: '',
  phone1: '',
  phone2: '',
  address: '',
  isCommonly: false,
};

const laboratorySlice = createSlice({
  name: 'settingPage/laboratory',
  initialState: {
    laboratory: [],
    lab: form,
    editId: '',
    deleteTarget: {
      _id: '',
      name: '',
    },
    loading: false,
  },
  reducers: {
    getLaboratoryStart: state => {
      state.loading = true;
    },
    getLaboratory: (state, action) => {
      state.laboratory = action.payload;
      state.loading = false;
    },
    editLaboratory: (state, action) => {
      state.lab = { ...state.lab, ...action.payload };
    },
    resetLabForm: state => {
      state.lab = form;
      state.editId = '';
    },
    selectLabItem: (state, action) => {
      const current = action.payload;
      state.lab.name = current.name;
      state.lab.description = current.description;
      state.lab.contact = current.contact;
      state.lab.phone1 = current.phone1;
      state.lab.phone2 = current.phone2;
      state.lab.address = current.address;
      state.editId = current._id;
    },
    getDeleteTarget: (state, action) => {
      state.deleteTarget._id = action.payload.id;
      state.deleteTarget.name = action.payload.name;
    },
    resetDeleteForm: state => {
      state.deleteTarget._id = '';
      state.deleteTarget.name = '';
    },
    updateOriginalLabs: (state, action) => {
      state.laboratory = state.laboratory.map(lab => {
        if (lab._id === action.payload._id) {
          return action.payload;
        }
        return lab;
      });
    },
    filterDeleteData: (state, action) => {
      state.laboratory = state.laboratory.filter(lab => lab._id !== action.payload._id);
    },
  },
});

export const {
  getLaboratoryStart,
  getLaboratory,
  editLaboratory,
  resetLabForm,
  selectLabItem,
  updateOriginalLabs,
  filterDeleteData,
  getDeleteTarget,
  resetDeleteForm,
} = laboratorySlice.actions;
export default laboratorySlice.reducer;
