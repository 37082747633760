import { createSlice } from '@reduxjs/toolkit';

const errorCenterSlice = createSlice({
  name: 'homePage/errorCenter',
  initialState: {
    usedList: [],
    serverError: false,
    clientError: false,
    duplicateName: false,
    passwordError: false,
    usedCaseModalVisible: false,
    hasChildrenModalVisible: false,
  },
  reducers: {
    getUsedList: (state, action) => {
      state.usedList = action.payload;
    },
    resetUsedList: state => {
      state.usedLists = [];
    },
    setServerError: (state, action) => {
      state.serverError = action.payload;
    },
    setClientError: (state, action) => {
      state.clientError = action.payload;
    },
    setDuplicateName: (state, action) => {
      state.duplicateName = action.payload;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    setHasChildrenModalVisible: (state, action) => {
      state.hasChildrenModalVisible = action.payload;
    },
    setUsedCaseModalVisible: (state, action) => {
      state.usedCaseModalVisible = action.payload;
    },
  },
});

export const {
  getUsedList,
  resetUsedList,
  setServerError,
  setClientError,
  setDuplicateName,
  setPasswordError,
  setHasChildrenModalVisible,
  setUsedCaseModalVisible,
} = errorCenterSlice.actions;
export default errorCenterSlice.reducer;
