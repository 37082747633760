import styled from 'styled-components';

//#region
const EllipsisContainer = styled.div`
  display: flex;
  width: 90%;
  & > span {
    white-space: nowrap;
  }
`;
const EllipsisSpan = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
`;
//#endregion

const EllipsisText = ({ children }) => {
  return <EllipsisContainer>{children}</EllipsisContainer>;
};

export default EllipsisText;

const Normal = ({ children }) => {
  return <span>{children}</span>;
};

const Ellipsis = ({ children }) => {
  return <EllipsisSpan>{children}</EllipsisSpan>;
};

EllipsisText.Normal = Normal;
EllipsisText.Ellipsis = Ellipsis;
