import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { updateOpenKeysInMenu } from '../../container/HomePage/slice/settingSlice';

const { SubMenu } = Menu;

const checkKey = key => key.indexOf('/') === -1;

const findKeys = menu => {
  let keys = [];
  menu.forEach(m => {
    if (m.sub) {
      m.sub.forEach(sub => {
        if (checkKey(sub.key)) {
          keys.push(sub.key);
        }
      });
    }
    if (checkKey(m.key)) {
      keys.push(m.key);
    }
  });
  return keys;
};

const settingSelector = state => state.homePageReducer.setting;
const settingReselector = createSelector(settingSelector, setting => ({
  keys: setting.openKeysInMenu,
}));

const PanelMenu = ({ menu, showIcon, className, closeHandler = () => {} }) => {
  const { keys } = useSelector(settingReselector);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {menu.map(setting => {
        return (
          <Menu
            mode='inline'
            key={setting.key}
            defaultSelectedKeys={[window.location.pathname]}
            defaultOpenKeys={findKeys(menu)}
            selectedKeys={[window.location.pathname]}
            className={className}
            openKeys={keys}
            onOpenChange={openKeys => dispatch(updateOpenKeysInMenu(openKeys))}
          >
            {setting.sub && (
              <SubMenu key={setting.key} title={setting.name} icon={showIcon ? setting.icon : null}>
                {setting.sub.map(firstSubMenu => {
                  if (firstSubMenu.sub) {
                    return (
                      <SubMenu title={firstSubMenu.name} key={firstSubMenu.key} className='second-subMenu'>
                        {firstSubMenu.sub.map(secondSubMenu => (
                          <Menu.Item key={secondSubMenu.key}>
                            <Link to={secondSubMenu.key} onClick={closeHandler}>
                              {secondSubMenu.name}
                            </Link>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={firstSubMenu.key}>
                      <Link to={firstSubMenu.key} onClick={closeHandler}>
                        {firstSubMenu.name}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            )}
            {!setting.sub && (
              <Menu.Item key={setting.key} icon={showIcon ? setting.icon : null}>
                <Link to={setting.link} onClick={closeHandler}>
                  {setting.name}
                </Link>
              </Menu.Item>
            )}
          </Menu>
        );
      })}
    </React.Fragment>
  );
};

export default PanelMenu;
