import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const settingSlice = createSlice({
  name: 'homePage/settings',
  initialState: {
    // locale: navigator.language,
    locale: 'zh-TW',
    openKeysInMenu: ['clinic', 'prescriptionItems'],
    alertModalInfo: {
      open: false,
      title: undefined,
      content: undefined,
      hasAuth: false,
      code: undefined
    },
  },
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
    updateOpenKeysInMenu: (state, action) => {
      state.openKeysInMenu = action.payload;
    },
    updateAlertModal: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          alertModalInfo: { ...state.alertModalInfo, ...action.payload },
        };
      }
    },
  },
});

export const { changeLocale, updateOpenKeysInMenu, updateAlertModal } = settingSlice.actions;
export default settingSlice.reducer;
