import { useEffect } from 'react';
import ReactGA from 'react-ga';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

export const usePageView = () => {
  const location = useLocation();
  useEffect(() => {
    GA.pageView(location);
  }, [location]);
};

export const CATEGORY = {
  case: 'case',
  home: 'home',
};

export const ACTION = {
  addNewCase: 'addNewCase',
  changeCase: 'changeCase',
};

export default class GA {
  static init = code => {
    ReactGA.initialize(code);
  };

  static pageView = location => {
    if (location) {
      ReactGA.pageview(location.pathname + location.search);
    }
  };

  static setUserId = id => {
    if (id) {
      ReactGA.set({ userId: id });
    }
  };

  static event = (category, action, value) => {
    const obj = _.pickBy(
      {
        category: category,
        action: action,
        value: value,
      },
      _.identity,
    );
    ReactGA.event(obj);
  };
}
