export const filterByDropDown = ({ source, filters }) => {
  const showAll = filters.includes('activate') && filters.includes('isArchived');
  const showNone = filters.length === 0;

  const filteredData = showAll
    ? source
    : showNone
    ? []
    : filters[0] === 'activate'
    ? source.filter(s => !s.isArchived)
    : source.filter(s => s.isArchived);

  return filteredData.map(d => {
    return { ...d, key: d._id };
  });
};
