import { useNavigate } from 'react-router-dom';
import { removeWorkspaceId } from '@/utils/token';
import ErrorComponent from '../../component/Error';
import { redirectToWsList } from '@/utils/redirect';

const ErrorPage = ({ errorTitle, errorDescription, errorCode = 403, showLogout = false, currentUser = {}, isClinicAccount = false, accountInfo = {} }) => {
  const navigate = useNavigate();

  return (
    <ErrorComponent
      errorTitle={errorTitle}
      errorDescription={errorDescription}
      errorCode={errorCode}
      buttonText={errorCode === 404 ? 'notFound.button' : 'error.go.workspace'}
      clickHandler={() => {
        removeWorkspaceId();
        if (errorCode === 404) {
          navigate('/');
        } else {
          return redirectToWsList();
        }
      }}
      showLogout={showLogout}
      currentUser={currentUser}
      isClinicAccount={isClinicAccount}
      accountInfo={accountInfo}
    />
  );
};

export default ErrorPage;
