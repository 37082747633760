export const settingMenu = (format, clinic) => [
  {
    key: 'clinic',
    name: format({ id: 'labSetting.menu.clinicSetting' }),
    sub: [
      { key: `/${clinic}/setting/lab`, name: format({ id: 'labSetting.menu.labList' }) },
      {
        key: 'prescriptionItems',
        name: format({ id: 'labSetting.menu.prescriptionItems' }),
        sub: [
          { key: `/${clinic}/setting/case-li`, name: format({ id: 'labSetting.menu.prescription' }) },
          { key: `/${clinic}/setting/case-type`, name: format({ id: 'labSetting.menu.category' }) },
          { key: `/${clinic}/setting/case-pay`, name: format({ id: 'labSetting.menu.paymentMethod' }) },
        ],
      },
    ],
  },
];

export const labSortSelectOption = format => [
  {
    label: format({ id: 'labSetting.table.sortByName' }),
    value: 'name',
  },
  {
    label: format({ id: 'labSetting.table.sortByLatest' }),
    value: 'latest',
  },
];

export const labSettingFilters = format => [
  { label: format({ id: 'labSetting.table.filterByActive' }), value: 'activate' },
  { label: format({ id: 'labSetting.table.filterByArchived' }), value: 'isArchived' },
];
