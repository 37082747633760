import { edit, get } from '../utils/api';
import { mapCaseOptionsToFilter } from '../container/HomePage/component/DashboardTableSearch/utils/mapCaseOptionsToFilter';

const requestUrl = `/labOrder`;
const requestUrlForPatient = `/labOrderByPatientId`;
const requestUrlForCaseName = `/getLabOrderByName`;
export default class Case {
  static getCase = async () => {
    const result = await get(requestUrl);
    return result;
  };

  static getCaseById = async params => {
    const result = await get(`${requestUrl}/${params}`);
    return result;
  };

  static getCaseByCaseName = async name => {
    const query = {
      name,
    };
    const result = await get(requestUrlForCaseName, query);
    return result;
  };

  static getCaseByPatientId = async params => {
    const query = {
      patientId: params,
    };
    const result = await get(requestUrlForPatient, query);
    return result;
  };

  static createCase = async data => {
    const result = await edit({ url: requestUrl, method: 'post', data });
    return result;
  };

  static editCase = async data => {
    const result = await edit({ url: requestUrl, method: 'put', data });
    return result;
  };

  static getCaseByOptions = async params => {
    const { progress } = params?.datePicked;
    const sort = [
      {
        property: 'progressPackage.completedDate',
        direction: 'DESC',
      },
    ];
    if (progress === 'estimatedReturnDate') sort[0].property = 'estimatedReturnDate';
    if (progress === 'createdDate') sort[0].property = 'createdDate';

    const filter = mapCaseOptionsToFilter(params);
    const query = {
      filters: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    };
    const result = await get(requestUrl, query);
    return result;
  };

  static getCaseAmount = async params => {
    const filter = mapCaseOptionsToFilter(params);
    const query = {
      filters: JSON.stringify(filter),
      onlyCount: true,
    };
    const result = await get(requestUrl, query);
    return result;
  };
}
