import { get } from '../utils/api';

const requestUrl = `/validation/deleted`;

export default class Validate {
  static deleteLabValidation = async _id => {
    const params = {
      target: 'laboratory',
      _id,
    };
    const result = await get(requestUrl, params);
    return result;
  };
  static deleteCaseLiCategoryValidation = async _id => {
    const params = {
      target: 'instructionBookCategory',
      _id,
    };
    const result = await get(requestUrl, params);
    return result;
  };
  static deleteCaseLiItemValidation = async _id => {
    const params = {
      target: 'InstructionBookItem',
      _id,
    };
    const result = await get(requestUrl, params);
    return result;
  };
  static deleteCasePayValidation = async _id => {
    const params = {
      target: 'paymentMethod',
      _id,
    };
    const result = await get(requestUrl, params);
    return result;
  };
  static deleteCaseTypeValidation = async _id => {
    const params = {
      target: 'treatmentCategory',
      _id,
    };
    const result = await get(requestUrl, params);
    return result;
  };
  static deleteProgressValidation = async _id => {
    const params = {
      target: 'progressItem',
      _id,
    };
    const result = await get(requestUrl, params);
    return result;
  };
}
