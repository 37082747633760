import styled from 'styled-components';

//#region
const Mask = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease;
`;
const IconButton = styled.span`
  background-color: var(--default-500-light);
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 19px;
  }
`;
//#endregion

const MaskButton = ({ className = 'mask', handleClick, icon }) => {
  return (
    <Mask className={className}>
      <IconButton onClick={handleClick} role='button'>
        {icon}
      </IconButton>
    </Mask>
  );
};

export default MaskButton;
