import { css } from 'styled-components';

export const scrollbar = css`
  &::-webkit-scrollbar {
    width: auto;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: 8px;
    border: 5px solid var(--default-500-light);
    &:hover {
      background-color: var(--scrollbar-thumb-hover);
    }
  }
`;
