import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken, getWorkspaceId, removeWorkspaceId, saveWorkspaceId } from '@/utils/token';
import { apiHeadersType, wsSubsystemApiUrl } from '@/config';
import { getWs, getWsList } from '@/container/HomePage/slice/workspaceSlice';

export const baseWsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: wsSubsystemApiUrl,
    prepareHeaders: headers => {
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  reducerPath: 'baseWsApi',
  endpoints: build => ({
    getWsList: build.query({
      query: () => {
        return {
          url: `/auth/workspace-list`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
          },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!_.isEmpty(data) && data.length === 1 && _.isEmpty(getWorkspaceId())) {
            saveWorkspaceId(data[0]._id);
          }
          dispatch(getWsList(data));
        } catch (err) {
          dispatch(getWsList([]));
          console.error(err);
        }
      },
    }),
    getWs: build.query({
      query: workspace_id => {
        return {
          url: `/auth/workspace-info`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
            workspace_id,
          },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data = {} } = await queryFulfilled;
          dispatch(getWs(data));
        } catch (err) {
          removeWorkspaceId();
          dispatch(getWs({}));
          console.error(err);
        }
      },
    }),
    getUrlWs: build.query({
      query: workspace_id => {
        return {
          url: `/auth/workspace-info`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
            workspace_id,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetWsListQuery, useGetWsQuery, useGetUrlWsQuery } = baseWsApi;
