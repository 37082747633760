import CaseType from '../../../models/caseType';
import { getCaseType, getCaseTypeStart, updateOriginalCaseType, filterDeleteData } from '../slice/caseTypeSlice';

export const getCaseTypeData = () => async dispatch => {
  dispatch(getCaseTypeStart());
  return await CaseType.getCaseType().then(res => dispatch(getCaseType(res)));
};

export const createCaseType = async (data, successCallback) => {
  return await CaseType.createCaseType(data).then(res => {
    if (!!res) {
      successCallback();
    }
  });
};

export const editCaseType = (data, successCallback) => async dispatch => {
  return await CaseType.editCaseType(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(updateOriginalCaseType(res));
    }
  });
};

export const deleteCaseType = (data, successCallback) => async dispatch => {
  return await CaseType.editCaseType(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(filterDeleteData(data));
    }
  });
};
