import { createSlice } from '@reduxjs/toolkit';
import { getSData, removeSToken, setSToken } from '@/utils/shareToken';
import { updateLabCase, updateSharedCase } from './caseSlice';
import { socket } from '@/socket';

const authSlice = createSlice({
  name: 'sharedPage/authentication',
  initialState: {
    loading: false,
    isLogin: false,
    passwordFailed: false,
    shareable: false,
    shareableLoading: false,
    name: '',
    clinic: '',
    passwordChanged: false,
    settingChanged: false,
    currentUser: {},
  },
  reducers: {
    fetching: state => {
      state.loading = true;
    },
    authenticated: (state, action) => {
      state.loading = false;
      if (action.payload.login) {
        state.isLogin = action.payload.login;
        state.passwordChanged = false;
        state.settingChanged = false;
        if (action.payload.token) {
          setSToken(action.payload.token, state.currentUser.role, state.currentUser.name);
          state.currentUser = {};
        }
      } else {
        state.isLogin = action.payload.login;
        state.passwordFailed = action.payload.passwordFailed;
      }
    },
    fetchingSharedStatus: (state, action) => {
      state.shareableLoading = action.payload;
    },
    getSharedStatus: (state, action) => {
      state.shareable = action.payload;
      state.shareableLoading = false;
    },
    loginInvalid: state => {
      state.isLogin = false;
    },
    setInfo: (state, action) => {
      state.clinic = action.payload.clinic;
      state.name = action.payload.name;
    },
    resetPasswordValidation: state => {
      state.passwordFailed = false;
    },
    setPasswordChanged: state => {
      state.passwordChanged = true;
    },
    setSettingChanged: state => {
      state.settingChanged = true;
    },
    setCurrentUser: state => {
      const user = getSData();
      if (user?.role && user?.name) {
        state.currentUser = { role: user?.role, name: user?.name };
      }
    },
    setLogout: state => {
      removeSToken();
      state.isLogin = false;
      socket.disconnect();
    },
  },
  extraReducers: {
    [updateLabCase](state, action) {
      if (action.payload.isDeleted) {
        state.settingChanged = true;
      }
    },
    [updateSharedCase](state, action) {
      if (!action.payload.enable) {
        state.settingChanged = true;
      }
    },
  },
});

export const {
  authenticated,
  fetching,
  fetchingSharedStatus,
  getSharedStatus,
  loginInvalid,
  setInfo,
  resetPasswordValidation,
  setPasswordChanged,
  setCurrentUser,
  setLogout,
  setSettingChanged,
} = authSlice.actions;

export default authSlice.reducer;
