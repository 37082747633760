import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import notFoundBg from '../../images/037-drawkit-nature-man-colour-copy-2.svg';
import { Button } from '../../component/Button';
import { removeWorkspaceId } from '@/utils/token';
import Logout from '@/component/Logout';

//#region
const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Error = styled.div`
  background-color: var(--default-500-light);
  width: 344px;
  min-height: 288px;
  position: relative;
  padding: 0 16px;
  border-radius: 8px;
  .c-error-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 28px;
  }
  & > div {
    text-align: center;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    background-image: url(${notFoundBg});
    background-size: contain;
    width: 219px;
    height: 162px;
    top: -127px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: 960px) {
    width: 412px;
    height: 394px;
    padding: 0 36px;
    &:before {
      width: 292px;
      height: 216px;
      top: -169px;
    }
    .c-error-title {
      font-size: 36px;
      margin-top: 2em;
    }
  }
  @media (min-width: 768px) and (max-width: 959px) {
    width: 360px;
    padding: 0 24px;
  }
`;
const ButtonWrap = styled.div`
  > button:nth-child(1) {
    margin-top: 28px;
  }
  > button:nth-child(2) {
    margin-top: 16px;
    margin-bottom: 48px;
  }
`;
const LogoutWrap = styled.div`
  margin-top: 48px;
`;
//#endregion

const ErrorComponent = ({
  errorTitle,
  errorDescription,
  errorCode = '404',
  clickHandler,
  buttonText = 'error.go.login',
  buttonDisplay = true,
  showLogout = false,
  currentUser = {},
  isClinicAccount = false,
  accountInfo = {}
}) => {
  const navigate = useNavigate();

  return (
    <ErrorContainer>
      <Error>
        <div className='c-error-title'>
          <h1>{errorCode}</h1>
          <span>{errorTitle}</span>
        </div>
        <div>{errorDescription}</div>
        {buttonDisplay && (
          <ButtonWrap>
            <Button type='primary' isBlock={true} size='large' onClick={clickHandler} buttonText={buttonText} />
            <Button type='ghost-primary' isBlock={true} size='large' onClick={() => {
              removeWorkspaceId();
              navigate('/');
            }} buttonText={'error.go.to.landing'} />
          </ButtonWrap>
        )}
      </Error>
      {
        showLogout && <LogoutWrap>
          <Logout
            currentUser={currentUser}
            isClinicAccount={isClinicAccount}
            accountInfo={accountInfo}
          />
        </LogoutWrap>
      }
    </ErrorContainer>
  );
};

export default ErrorComponent;
