import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { ReactComponent as LogoutIcon } from '../../../images/1-2-icon-his-icons-log-out.svg';

//#region
const LogoutButton = styled(Button)`
  width: 288px;
  height: 40px;
  border: 0;
  font-size: 13px;
  display: flex;
  align-items: center;
  box-shadow: none;
  margin: 3px 8px 12px 8px;
  &:hover {
    background-color: var(--color-fill-1-primary-100);
    color: var(--dark);
  }
`;
const LogoutText = styled.span`
  padding-left: 8.5px;
`;
//#endregion

const Logout = ({ logoutHandler, f }) => {
  return (
    <LogoutButton icon={<LogoutIcon />} onClick={logoutHandler}>
      <LogoutText>{f({ id: 'navbar.logout' })}</LogoutText>
    </LogoutButton>
  );
};

export default Logout;
