import { Input, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { VerticalInput } from '../../../component/Input';
import { editLaboratory } from '../slice/laboratorySlice';
import { setDuplicateName } from '../../HomePage/slice/errorCenterSlice';

const { TextArea } = Input;

//#region
const StyledTextArea = styled.div`
  font-size: 13px;
  textarea.ant-input {
    margin-top: 3px;
    resize: none;
    min-height: 70px;
  }
`;

const LabFormContainer = styled.div`
  & > div {
    padding-bottom: 30px;
  }
  .ant-checkbox {
    margin-right: 8px;
  }
`;
//#endregion

const labSettingReselector = createSelector(
  state => state.settingPageReducer.laboratory,
  ({ laboratory, lab }) => ({
    laboratory,
    lab,
  }),
);

const LabSettingForm = ({ control, error, validateName }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const { lab } = useSelector(labSettingReselector);

  return (
    <LabFormContainer>
      <VerticalInput
        required={true}
        alertMessage={'form.alert'}
        label={'labSetting.form.name'}
        value={lab.name}
        changeHandler={value => {
          dispatch(editLaboratory({ name: value }));
          dispatch(setDuplicateName(false));
        }}
        placeholder={'input.placeholder'}
        size={'large'}
        control={control}
        error={error}
        validationName={'name'}
        validateError={validateName}
      />
      <StyledTextArea>
        <label>
          {f({ id: 'labSetting.form.note' })}
          <TextArea
            value={lab.description}
            onChange={e => dispatch(editLaboratory({ description: e.target.value }))}
            placeholder={f({ id: 'input.placeholder' })}
          />
        </label>
      </StyledTextArea>
      <VerticalInput
        label={'labSetting.form.contactPerson'}
        value={lab.contact}
        changeHandler={e => dispatch(editLaboratory({ contact: e.target.value }))}
        placeholder={'input.placeholder'}
        size={'large'}
      />
      <VerticalInput
        label={'labSetting.form.contact'}
        value={lab.phone1}
        changeHandler={e => dispatch(editLaboratory({ phone1: e.target.value }))}
        placeholder={'input.placeholder'}
        size={'large'}
      />
      <VerticalInput
        label={'labSetting.form.contactExtra'}
        value={lab.phone2}
        changeHandler={e => dispatch(editLaboratory({ phone2: e.target.value }))}
        placeholder={'input.placeholder'}
        size={'large'}
      />
      <VerticalInput
        label={'labSetting.form.address'}
        value={lab.address}
        changeHandler={e => dispatch(editLaboratory({ address: e.target.value }))}
        placeholder={'input.placeholder'}
        size={'large'}
      />
      <div>
        <label>
          <Checkbox onChange={e => dispatch(editLaboratory({ isCommonly: e.target.checked }))} />
          {f({ id: 'labSetting.form.mark' })}
        </label>
      </div>
    </LabFormContainer>
  );
};

export default LabSettingForm;
