import { Alert } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as BlackCloseIcon } from '../../images/icons-close.svg';
import { ReactComponent as GrayCloseIcon } from '../../images/0-icon-close-outline-thick.svg';
import { ReactComponent as SuccessIcon } from '../../images/0-icon-fill-check-circle-fill.svg';
import { ReactComponent as ErrorIcon } from '../../images/0-icon-fill-close-circle-fill.svg';
import { ReactComponent as InfoIcon } from '../../images/0-icon-fill-info-circle-fill.svg';
import { ReactComponent as WarningIcon } from '../../images/0-icon-fill-warning-circle-fill.svg';

//#region
const StyledAlert = styled(Alert)`
  border: none;
  .ant-alert-message {
    font-size: 13px;
  }

  ${props =>
    props.type === 'success' &&
    `
    background-color: var(--light-100-success);
`}
  ${props =>
    props.type === 'info' &&
    `
    background-color: var(--color-fill-3-info-100);
`}
${props =>
    props.type === 'warning' &&
    `
    background-color: var(--light-100-warning);
`}
${props =>
    props.type === 'error' &&
    `
    background-color: var(--light-100-danger);
`}
${props =>
    props.description &&
    `
    .ant-alert-message {
        font-size: 18px;
        font-weight: 600;
    }
    .ant-alert-description {
        font-size: 13px;
    }
    .ant-alert-close-text svg {
        width: 20px;
    }
    .ant-alert-icon {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }
`}
`;
//#endregion

const CustomizedAlert = ({ message, type, actionElement, showIcon, closable, description, banner }) => {
  const generateIcon = type => {
    switch (type) {
      case 'success': {
        return <SuccessIcon />;
      }
      case 'info': {
        return <InfoIcon />;
      }
      case 'warning': {
        return <WarningIcon />;
      }
      case 'error': {
        return <ErrorIcon />;
      }
      default: {
        return null;
      }
    }
  };
  return (
    <StyledAlert
      message={message}
      type={type}
      showIcon={showIcon}
      action={actionElement}
      description={description}
      banner={banner}
      closeText={!closable ? undefined : description ? <BlackCloseIcon /> : <GrayCloseIcon />}
      icon={generateIcon(type)}
    />
  );
};

export default CustomizedAlert;

CustomizedAlert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  showIcon: PropTypes.bool,
  actionElement: PropTypes.element,
  closable: PropTypes.bool,
  description: PropTypes.string,
  banner: PropTypes.bool,
};

CustomizedAlert.defaultProps = {
  message: '',
  type: 'info',
  showIcon: false,
  actionElement: undefined,
  closable: false,
  description: '',
  banner: false,
};
