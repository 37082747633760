import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Navbar from '../Navbar';
import { setErrorId } from '@/container/CasePage/slice/caseSettingSlice';
import { getWorkspaceId, removeWorkspaceId } from '@/utils/token';
import { useGetUrlWsQuery, useGetWsListQuery, useGetWsQuery } from '@/services/ws/wsApi';
import { createSelector } from '@reduxjs/toolkit';
import { getWs } from '@/container/HomePage/slice/workspaceSlice';
import { GRANT, PENDING } from '@/constants/licenseState';
import Loading from '@/component/Loading';
import { updateAlertModal } from '@/container/HomePage/slice/settingSlice';
import ErrorPage from '@/container/ErrorPage';
import parseErrorContent from '@/utils/error';
import SSORedirectUtil from '@/component/Auth/utils/ssoRedirectUtil';
import { errorStatus } from '@/constants/errorStatus';
import { hasCharacterPermission } from '@/utils/permission';
import { parseAccountDetail } from '@/utils/parseAccountDetail';

//#region
const MainContainer = styled.div`
  background-color: var(--background-1);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
//#endregion

const reSelector = createSelector(
  state => ({
    ...state.homePageReducer.setting,
    ...state.homePageReducer.ws
  }),
  ({ alertModalInfo, ws, wsList, member, wsConfig }) => ({ alertModalInfo, ws, wsList, member, wsConfig }),
);

const MainLayout = ({ currentUser }) => {
  const { formatMessage: f } = useIntl();
  const currentWsId = getWorkspaceId();
  const { clinicId: urlWsId } = useParams();
  const { errorId } = useSelector(state => state.casePageReducer.caseSetting);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alertModalInfo, ws, wsList, member, wsConfig } = useSelector(reSelector);
  const [isFetching, setIsFetching] = useState(false);

  // region - ws
  const {
    refetch: wsListReFetch,
    isUninitialized: isWsListUninitialized,
    isLoading: isWsListLoading,
    isSuccess: isGetWsListSuccess,
    isError: isGetWsListError,
    error: wsListError,
  } = useGetWsListQuery(null, { skip: _.isEmpty(currentUser) });
  const {
    refetch: wsReFetch,
    isUninitialized: isWsUninitialized,
    isSuccess: isGetWsSuccess,
    isLoading: isWsLoading,
    isError: isGetWsError,
    error: wsError,
  } = useGetWsQuery(currentWsId, {
    skip: _.isNil(wsList) || _.isEmpty(wsList) || _.isEmpty(currentWsId),
  });
  const {
    data: urlWsData,
    isSuccess: isGetUrlWsSuccess,
    isLoading: isUrlWsLoading,
    isError: isGetUrlWsError,
    error: urlWsError,
  } = useGetUrlWsQuery(urlWsId, {
    skip: _.isNil(wsList) || _.isEmpty(wsList) || _.isEmpty(urlWsId) || urlWsId === currentWsId
  });
  // endregion
  const isMainLoading = isFetching || isWsLoading || isWsListLoading || isUrlWsLoading;
  const isMainFetchError = isGetWsError || isGetWsListError || isGetUrlWsError;

  useEffect(() => {
    if (_.isNil(currentUser)) {
      setIsFetching(true);
    } else if (!_.isNil(currentUser) && _.isEmpty(currentUser)) {
      setIsFetching(true);
      return SSORedirectUtil.redirectCasLoginPath(true);
    } else {
      setIsFetching(true);
      if (!!wsList && isGetWsListSuccess) {
        if (_.isEmpty(wsList) && !_.isEmpty(currentWsId)) {
          // 我現在沒有任何 ws 但卻記錄過 wsId
          removeWorkspaceId();
        }
        if (_.isEmpty(wsList)) {
          // 沒有 ws, 但卻想要進 url ws
          dispatch(
            updateAlertModal({
              open: true,
              title: f({id: 'error.auth.title'}),
              content: f({id: 'error.auth.description'}),
              code: errorStatus.ERR_WS_MEMBER_UNAUTHORIZED
            }),
          );
        } else if (currentWsId !== urlWsId) {
          // url 的 wsId 跟我本來選擇的 wsId 不同
          if (!!urlWsData && isGetUrlWsSuccess) {
            // 有找到網址上貼的那間 ws 資料, 代表我屬於這間 ws, 不管我現在選的是哪間 ws, 都切換到現在這間 ws, 並更新現在的 ws 資料
            dispatch(getWs(urlWsData));
          } else if (isGetUrlWsError) {
            // urlWs 資料 fetch failed or not belong to this ws
            dispatch(
              updateAlertModal({
                open: true,
                title: f({id: 'error.auth.title'}),
                content: f({id: 'error.auth.description'}),
                code: errorStatus.ERR_WS_MEMBER_UNAUTHORIZED
              }),
            );
          }
        } else {
          if (!!ws && isGetWsSuccess) {
            // 代表我本來選擇的 ws 已經跟 url 上的是一樣的, 直接找 ws 資料
            if (ws?.license_state !== GRANT || _.isEmpty(wsConfig?.his_url)) {
              // ws license 還沒驗證通過
              const hasSettingPermission =
                !_.isEmpty(member?.workspace_permissions) &&
                member?.workspace_permissions?.some(p => p === 'workspace_setting');
              dispatch(
                updateAlertModal({
                  open: true,
                  title: f({id: 'error.unauthenticated.title'}),
                  content:
                    (ws?.license_state === PENDING && _.isEmpty(wsConfig?.his_url))
                      ? f({id: 'error.auth.description.unauthenticated'})
                      : f({id: 'error.auth.description.authNotAllow'}),
                  hasAuth: hasSettingPermission && ws?.license_state !== PENDING,
                  code: errorStatus.ERR_WS_UNAUTHENTICATED
                }),
              );
            } else {
              // 角色沒有權限
              if (!hasCharacterPermission(member?.service_permission)) {
                dispatch(
                  updateAlertModal({
                    open: true,
                    title: f({id: 'error.auth.title'}),
                    content: f({id: 'error.auth.description'}),
                    code: errorStatus.ERR_WS_MEMBER_UNAUTHORIZED
                  }),
                );
              }
            }
          }
        }
        setIsFetching(false);
      }
    }
  }, [currentUser, f, dispatch, navigate, wsList, isGetWsListSuccess, currentWsId, urlWsId, urlWsData, isGetUrlWsSuccess, isGetUrlWsError, ws, isGetWsSuccess, member, wsConfig])

  useEffect(() => {
    dispatch(setErrorId(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMainLoading) {
    return <Loading />;
  } else if (isMainFetchError || !!errorId) {
    return <ErrorPage
      {...parseErrorContent(isGetWsListError
        ? wsListError.data
        : isGetWsError
          ? wsError.data
          : isGetUrlWsError
            ? urlWsError.data
            : undefined, f)}
      currentUser={currentUser}
      isClinicAccount={!!currentUser?.is_clinic_account}
      accountInfo={parseAccountDetail(currentUser)}
    />
  } else if (!_.isNil(alertModalInfo.content)) {
    return <ErrorPage
      {...parseErrorContent(alertModalInfo, f)}
      currentUser={currentUser}
      isClinicAccount={!!currentUser?.is_clinic_account}
      accountInfo={parseAccountDetail(currentUser)}
    />;
  } else {
    return (
      <MainContainer>
        <Navbar ws={ws} member={member} currentUser={currentUser} />
        <Outlet
          context={{
            currentUser,
            isWsUninitialized,
            wsReFetch,
            isWsListUninitialized,
            wsListReFetch,
            ws,
            wsList,
            member,
            wsConfig,
            isGuest: _.isEmpty(currentUser),
            alertModalInfo,
          }}
        />
      </MainContainer>
    );
  }
};

export default MainLayout;
