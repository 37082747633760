import React, { useState } from 'react';
import styled from 'styled-components';
import LabSettingHeader from './component/LabSettingHeader';
import { Button } from '../../component/Button';
import { ReactComponent as PlusIcon } from '../../images/icons-plus.svg';
import CaseTypeContent from './component/CaseTypeContent';

//#region
export const CaseItemsContainer = styled.div`
  background: var(--default-500-light);
  border-radius: 8px;
`;
//#endregion

const CaseType = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [sort, setSort] = useState('name');
  const handleVisible = () => setDrawerVisible(true);
  const handleInvisible = () => setDrawerVisible(false);

  return (
    <CaseItemsContainer>
      <LabSettingHeader
        title='caseType.title'
        sortHandler={setSort}
        sortValue={sort}
        content={
          <div className='settingTitle-content'>
            <Button
              type='primary'
              width={'85px'}
              icon={<PlusIcon />}
              onClick={handleVisible}
              buttonText={'button.add'}
            />
          </div>
        }
      />
      <CaseTypeContent
        drawerVisible={drawerVisible}
        handleDrawerClose={handleInvisible}
        handleVisible={handleVisible}
        sort={sort}
      />
    </CaseItemsContainer>
  );
};

export default CaseType;
