export function setSToken(token, role, name) {
  let key = JSON.parse(atob(token.split('.')[1]))?.id;
  const value = {
    token,
    role,
    name,
  };
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSToken() {
  const target = window.location.pathname.split('/')[2];
  const token = JSON.parse(sessionStorage.getItem(target));
  return token?.token || '';
}

export function getSData() {
  const target = window.location.pathname.split('/')[2];
  return JSON.parse(sessionStorage.getItem(target));
}

export function removeSToken() {
  const target = window.location.pathname.split('/')[2];
  return sessionStorage.removeItem(target);
}

export function getTokenId(token) {
  return token ? JSON.parse(atob(token.split('.')[1]))?.id : undefined;
}
