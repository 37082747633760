import { createSlice } from '@reduxjs/toolkit';
import { splitArchived } from '../utils/splitArchived';

const caseLiSlice = createSlice({
  name: 'settingPage/caseLi',
  initialState: {
    caseCategory: [],
    archivedCategory: [],
    editingCategory: {
      _id: '',
      name: '',
    },
    caseItem: [],
    editingItem: {
      _id: '',
      name: '',
      isCommonly: false,
      categoryId: '',
    },
    deleting: {
      _id: '',
      name: '',
      type: '',
      isArchived: false,
    },
    loading: false,
    itemLoading: false,
    currentSelectCategoryId: '',
  },
  reducers: {
    getCaseLiStart: state => {
      state.loading = true;
    },
    getCaseLiSuccess: (state, action) => {
      const { category, item } = action.payload;
      const { normal, archived } = splitArchived(category);
      state.caseCategory = normal;
      state.archivedCategory = archived;
      state.caseItem = item;
      if (category.length !== 0) {
        const getFirstOne = category.filter(c => c.priority >= 1).sort((a, b) => a.priority - b.priority)[0];
        state.currentSelectCategoryId = getFirstOne?._id;
        state.editingItem.categoryId = getFirstOne?._id;
      }
      state.loading = false;
    },
    pushToCategory: (state, action) => {
      state.caseCategory.push(action.payload);
    },
    editingCategoryForm: (state, action) => {
      state.editingCategory = { ...state.editingCategory, ...action.payload };
    },
    updateCategoryOrder: (state, action) => {
      const { normal, archived } = splitArchived(action.payload);
      state.caseCategory = normal;
      state.archivedCategory = archived;
    },
    resetCategoryForm: state => {
      state.editingCategory.name = '';
      state.editingCategory._id = '';
    },
    getDeleteTarget: (state, action) => {
      state.deleting._id = action.payload.data._id;
      state.deleting.name = action.payload.data.name;
      state.deleting.type = action.payload.type;
      state.deleting.isArchived = action.payload.isArchived;
    },
    resetDeleteForm: state => {
      state.deleting._id = '';
      state.deleting.name = '';
    },
    selectCategoryItem: (state, action) => {
      const current = action.payload;
      state.editingCategory.name = current.name;
      state.editingCategory._id = current._id;
    },
    refreshCaseCategory: (state, action) => {
      let activate = [];
      let archived = [];
      action.payload.forEach(c => {
        if (c.isArchived) {
          archived.push(c);
        } else {
          activate.push(c);
        }
      });
      state.caseCategory = activate;
      state.archivedCategory = archived;
    },
    updateCategory: (state, action) => {
      const isArchived = action.payload.isArchived;
      if (isArchived) {
        state.archivedCategory = state.archivedCategory.map(c => {
          if (c._id === action.payload._id) {
            return action.payload;
          }
          return c;
        });
      }
      if (!isArchived) {
        state.caseCategory = state.caseCategory.map(c => {
          if (c._id === action.payload._id) {
            return action.payload;
          }
          return c;
        });
      }
    },
    filterDeletedCategory: (state, action) => {
      state.caseCategory = action.payload;
    },
    filterDeletedArchivedCategory: (state, action) => {
      state.archivedCategory = state.archivedCategory.filter(c => c._id !== action.payload._id);
    },
    editingItemForm: (state, action) => {
      state.editingItem = { ...state.editingItem, ...action.payload };
    },
    resetItemForm: state => {
      state.editingItem._id = '';
      state.editingItem.name = '';
      state.editingItem.isCommonly = false;
    },
    pushToItem: (state, action) => {
      state.caseItem.push(action.payload);
    },
    updateItem: (state, action) => {
      state.caseItem = state.caseItem.map(c => {
        if (c._id === action.payload._id) {
          return action.payload;
        }
        return c;
      });
    },
    selectCategory: (state, action) => {
      state.currentSelectCategoryId = action.payload;
      state.editingItem.categoryId = action.payload;
    },
    selectItem: (state, action) => {
      const current = action.payload;
      state.editingItem.name = current.name;
      state.editingItem._id = current._id;
      state.editingItem.isCommonly = current.isCommonly;
    },
    filterDeletedItem: (state, action) => {
      state.caseItem = state.caseItem.filter(c => c._id !== action.payload._id);
    },
  },
});

export const {
  getCaseLiStart,
  getCaseLiSuccess,
  pushToCategory,
  resetCategoryForm,
  selectCategoryItem,
  updateCategory,
  updateCategoryOrder,
  editingCategoryForm,
  getDeleteTarget,
  resetDeleteForm,
  editingItemForm,
  filterDeletedCategory,
  resetItemForm,
  selectCategory,
  pushToItem,
  updateItem,
  selectItem,
  filterDeletedItem,
  refreshCaseCategory,
  filterDeletedArchivedCategory,
} = caseLiSlice.actions;
export default caseLiSlice.reducer;
