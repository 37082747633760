import CaseLi from '../../../models/caseLi';
import {
  getCaseLiStart,
  getCaseLiSuccess,
  refreshCaseCategory,
  updateCategory,
  updateCategoryOrder,
  pushToCategory,
  filterDeletedCategory,
  pushToItem,
  updateItem,
  filterDeletedItem,
  selectCategory,
  filterDeletedArchivedCategory,
} from '../slice/caseLiSlice';

export const getCaseLi = () => async dispatch => {
  dispatch(getCaseLiStart());
  return Promise.all([CaseLi.getCaseCategory(), CaseLi.getCaseItem()])
    .then(([category, item]) => {
      dispatch(getCaseLiSuccess({ category, item }));
    })
    .catch(err => {
      console.log(err);
    });
};

export const getCaseCategory = () => async dispatch => {
  return await CaseLi.getCaseCategory().then(res => dispatch(refreshCaseCategory(res)));
};

export const createCaseCategory = (data, successCallback) => async dispatch => {
  return CaseLi.createCaseCategory(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(pushToCategory(res));
      dispatch(selectCategory(res._id));
    }
  });
};

export const editCaseCategory = (data, successCallback) => async dispatch => {
  return await CaseLi.editCaseCategory(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(updateCategory(res));
    }
  });
};

export const archivedCaseCategory = (data, successCallback) => async dispatch => {
  return await CaseLi.editCaseCategory(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(getCaseCategory());
    }
  });
};

export const sortCaseCategory = (data, successCallback) => async dispatch => {
  return await CaseLi.sortCaseCategory(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(updateCategoryOrder(res));
    }
  });
};

export const deleteCaseCategory = (data, successCallback, isArchived) => async dispatch => {
  return await CaseLi.editCaseCategory(data).then(res => {
    if (!!res) {
      successCallback();
      if (isArchived) {
        return dispatch(filterDeletedArchivedCategory(data));
      }
      return dispatch(filterDeletedCategory(res));
    }
  });
};

// item
export const createCaseItem = (data, successCallback) => async dispatch => {
  return await CaseLi.createCaseItem(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(pushToItem(res));
    }
  });
};

export const editCaseItem = (data, successCallback) => async dispatch => {
  return await CaseLi.editCaseItem(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(updateItem(res));
    }
  });
};

export const deleteCaseItem = (data, successCallback) => async dispatch => {
  return await CaseLi.editCaseItem(data).then(res => {
    if (!!res) {
      successCallback();
      dispatch(filterDeletedItem(data));
    }
  });
};
