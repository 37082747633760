import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Popover, Button as BtnAntd } from 'antd';
import { useIntl } from 'react-intl';
import UserInfo from './UserInfo';
import Logout from './Logout';
import Avatar from '../../Avatar';

//#region
const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  .ant-btn {
    padding: 0;
  }
`;

const PopoverButton = styled(BtnAntd)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  border: 0;
  background-color: ${props => (!_.isNil(props?.color) ? props.color : 'var(--color-fill-6-others-purple-500)')};
  color: var(--default-500-light);
  &:hover,
  &:focus {
    background-color: ${props => (!_.isNil(props?.color) ? props.color : 'var(--color-fill-6-others-purple-500)')};
    color: var(--default-500-light);
  }
`;
//#endregion

const Account = ({ ws, member, currentUser, logoutHandler, ...props }) => {
  const accountInfo = {
    memberName: !_.isEmpty(member) ? member?.name : currentUser?.name,
    avatar: !_.isEmpty(member) ? member?.avatar_url : currentUser?.avatar_url,
    textAvatar: !_.isEmpty(member?.name) ? member.name[0].toUpperCase() : !_.isEmpty(currentUser?.name) ? currentUser?.name[0].toUpperCase() : '',
    avatarColor: !_.isEmpty(member) ? member?.avatar_color_code : 'var(--basic-400)',
  };
  const { formatMessage: f } = useIntl();

  return (
    <AccountContainer>
      <Popover
        placement='bottomRight'
        title={<UserInfo {...props} {...accountInfo} />}
        content={<Logout logoutHandler={logoutHandler} f={f} />}
        trigger='click'
      >
        <PopoverButton color={_.isEmpty(accountInfo.avatar) ? accountInfo.avatarColor : ''}>
          {!_.isNil(accountInfo.avatar) ? <Avatar type='img' image={accountInfo.avatar} size={32} /> : <div>{accountInfo.textAvatar}</div>}
        </PopoverButton>
      </Popover>
    </AccountContainer>
  );
};

export default Account;
