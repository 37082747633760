import { createSlice } from '@reduxjs/toolkit';

const commentSlice = createSlice({
  name: 'sharedPage/case',
  initialState: {
    caseLoading: false,
    caseData: [],
    sharedCase: {},
  },
  reducers: {
    fetchCase: state => {
      state.caseLoading = true;
    },
    getCaseData: (state, action) => {
      state.caseData = action.payload.labCase;
      state.sharedCase = action.payload.sharedCase;
      state.caseLoading = false;
    },
    updateLabCase: (state, action) => {
      state.caseData = action.payload;
    },
    updateSharedCase: (state, action) => {
      state.sharedCase = action.payload;
    },
  },
});

export const { fetchCase, getCaseData, updateLabCase, updateSharedCase } = commentSlice.actions;

export default commentSlice.reducer;
