import { get } from '../utils/api';

const requestUrl = `/his/getAllDoctor`;

export default class Doctors {
  static getDoctors = async (size = 1000) => {
    const result = await get(`${requestUrl}?size=${size}`);
    return result;
  };
}
