import { edit, get } from '../utils/api';

const requestUrl = `/instructionBookCategory`;
const itemRequestUrl = `/instructionBookItem`;
const sortCategory = `/instructionBookCategorySort`;
export default class CaseLi {
  static getCaseCategory = async () => {
    const result = await get(requestUrl);
    return result;
  };

  static getCaseCategoryById = async params => {
    const result = await get(`${requestUrl}/${params}`);
    return result;
  };

  static createCaseCategory = async data => {
    const result = await edit({ url: requestUrl, method: 'post', data });
    return result;
  };

  static editCaseCategory = async data => {
    const result = await edit({ url: requestUrl, method: 'put', data });
    return result;
  };

  static sortCaseCategory = async data => {
    const result = await edit({ url: sortCategory, method: 'put', data });
    return result;
  };

  static getCaseItem = async () => {
    const result = await get(itemRequestUrl);
    return result;
  };

  static getCaseItemById = async params => {
    const result = await get(`${itemRequestUrl}/${params}`);
    return result;
  };

  static createCaseItem = async data => {
    const result = await edit({ url: itemRequestUrl, method: 'post', data });
    return result;
  };

  static editCaseItem = async data => {
    const result = await edit({ url: itemRequestUrl, method: 'put', data });
    return result;
  };
}
