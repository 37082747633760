import React from 'react';
import { message } from 'antd';
import { ReactComponent as SuccessIcon } from '../../images/icon-checkmark-circle-fill.svg';
import { ReactComponent as ErrorIcon } from '../../images/icon-alert-circle-fill.svg';
import { ReactComponent as DeletedIcon } from '../../images/icon-alert-triangle-fill.svg';

const messageSuccess = content => {
  message.success({
    content,
    icon: <SuccessIcon />,
    className: 'message message_success',
  });
};

const messageError = content => {
  message.error({
    content,
    icon: <ErrorIcon />,
    className: 'message message_error',
  });
};

const messageWarning = content => {
  message.warning({
    content,
    icon: <DeletedIcon />,
    className: 'message message_warning',
  });
  return <styledMessage />;
};

export { messageSuccess, messageError, messageWarning };
