import { Tooltip } from 'antd';
import StarMark from '../../../component/Button/StarMark';
import MoreButton from '../../../component/Button/MoreButton';
import { labSettingFilters } from '../constant';

const TableTooltip = ({ title, isArchived, children }) => {
  return (
    <Tooltip title={title} placement='topLeft'>
      <span className={isArchived ? 'labSetting__table-isArchived' : undefined}>{children ? children : title}</span>
    </Tooltip>
  );
};

export const labColumns = ({
  format,
  onEditLabItem,
  showWarningModal,
  activatedLabItem,
  addCommonlyMark,
  TableFilter,
  filters,
  filterHandler,
  showModal,
  deleteValidation,
}) => [
  {
    title: (
      <div>
        <span>{format({ id: 'labSetting.table.columnName' })}</span>
        <TableFilter options={labSettingFilters(format)} value={filters} onChange={filterHandler} />
      </div>
    ),
    key: 'name',
    ellipsis: {
      showTitle: false,
    },
    render: ({ name, isArchived }) => (
      <TableTooltip title={name} isArchived={isArchived}>
        {isArchived ? (
          <div>
            <div className='labSetting__table-archivedAlert'>{`(${format({
              id: 'button.more.dropDownMenu.archived',
            })})`}</div>
            <div>{name}</div>
          </div>
        ) : (
          name
        )}
      </TableTooltip>
    ),
  },
  {
    title: format({ id: 'labSetting.table.columnNote' }),
    key: 'description',
    ellipsis: {
      showTitle: false,
    },
    render: ({ description, isArchived }) => <TableTooltip title={description} isArchived={isArchived} />,
  },
  {
    title: format({ id: 'labSetting.table.columnContactPerson' }),
    key: 'contact',
    ellipsis: {
      showTitle: false,
    },
    render: ({ contact, isArchived }) => <TableTooltip title={contact} isArchived={isArchived} />,
  },
  {
    title: format({ id: 'labSetting.table.columnContact' }),
    key: 'phone1',
    ellipsis: {
      showTitle: false,
    },
    render: ({ phone1, isArchived }) => <TableTooltip title={phone1} isArchived={isArchived} />,
  },
  {
    title: format({ id: 'labSetting.table.columnContactExtra' }),
    key: 'phone2',
    ellipsis: {
      showTitle: false,
    },
    render: ({ phone2, isArchived }) => <TableTooltip title={phone2} isArchived={isArchived} />,
  },
  {
    title: format({ id: 'labSetting.table.columnAddress' }),
    key: 'address',
    ellipsis: {
      showTitle: false,
    },
    render: ({ address, isArchived }) => <TableTooltip title={address} isArchived={isArchived} />,
  },
  {
    title: '',
    key: 'more',
    render: record => (
      <div className='labSetting__table-more'>
        {!record.isArchived && (
          <StarMark isMark={record.isCommonly} clickHandler={() => addCommonlyMark(record._id, record.isCommonly)} />
        )}
        <MoreButton
          name={record.name}
          isActivate={!record.isArchived}
          editHandler={() => onEditLabItem(record)}
          archiveHandler={() => showWarningModal(record._id, record.name)}
          activateHandler={() => activatedLabItem(record._id)}
          delHandler={() => deleteValidation(record._id, record.name)}
        />
      </div>
    ),
    width: 100,
  },
];
