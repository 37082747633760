import moment from 'moment';

export default function sortByUpdateTime(arr) {
  if (arr) {
    return arr
      .slice()
      .sort(
        (a, b) => moment(b.lastUpdatedDate || b.createdDate).unix() - moment(a.lastUpdatedDate || a.createdDate).unix(),
      );
  }
  return [];
}
