export const ERR_SHEETA_0002 = 'SHEETA0002'; // workspace not found by id
export const ERR_SHEETA_0006 = 'SHEETA0006'; // workspace is not belong to the user
export const ERR_WS_UNAUTHENTICATED = 'ERR_WS_UNAUTHENTICATED'; // workspace 尚未被驗證通過
export const ERR_WS_UNAUTHENTICATED_AND_UNAUTHORIZED = 'ERR_WS_UNAUTHENTICATED_AND_UNAUTHORIZED'; // workspace 尚未被驗證通過且該 member 沒有申請驗證的權限
export const ERR_WS_MEMBER_UNAUTHORIZED = 'ERR_WS_MEMBER_UNAUTHORIZED'; // workspace 已被驗證通過，但該 member 沒有訪問權限

export const errorStatus = {
  ERR_SHEETA_0002,
  ERR_SHEETA_0006,
  ERR_WS_UNAUTHENTICATED,
  ERR_WS_UNAUTHENTICATED_AND_UNAUTHORIZED,
  ERR_WS_MEMBER_UNAUTHORIZED
};