import { Select } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { labSortSelectOption } from '../constant';
import { ReactComponent as ArrowDown } from '../../../images/icon-his-icons-chevron-down.svg';
import { ReactComponent as Checked } from '../../../images/icon-his-icons-checkmark.svg';

//#region
const LabTableSortContainer = styled.div`
  width: 157px;
  position: relative;
  color: var(--default-500-hint);

  .ant-select-dropdown {
    left: 0 !important;
  }
`;
const SortSelect = styled(Select)`
  width: 70px;
  font-size: 13px;
  .ant-select-item-option-content {
    font-size: 13px;
  }
`;
//#endregion

const LabSettingSelect = ({ selectHandler, value }) => {
  const { formatMessage: f } = useIntl();

  return (
    <LabTableSortContainer id={'setting__sort'}>
      <label>
        {f({ id: 'labSetting.table.sortLabel' })}：
        <SortSelect
          value={value}
          bordered={false}
          options={labSortSelectOption(f)}
          onChange={selectHandler}
          dropdownMatchSelectWidth={157}
          getPopupContainer={() => document.getElementById('setting__sort')}
          menuItemSelectedIcon={<Checked />}
          suffixIcon={<ArrowDown />}
        />
      </label>
    </LabTableSortContainer>
  );
};

export default LabSettingSelect;

LabSettingSelect.propTypes = {
  value: PropTypes.string,
  selectHandler: PropTypes.func,
};

LabSettingSelect.defaultProps = {
  value: 'name',
  selectHandler: () => {},
};
